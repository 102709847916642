import { useState } from 'react';

import { convertCentsToDollars } from 'freely-shared-utils';

import { Assets } from '@assets';
import { DiscountCodeInput, Text } from '@components';
import { BuyNowButtonSection } from '@components/sideBar/totalPriceAndGroupDiscount';
import { i18n } from '@i18n';
import { ROUTES, ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { useFeatureFlagsStore, useTripStore } from '@store';

export function StickyFooter() {
  const currentPathname = getCurrentPathname();
  const { myQuote } = ROUTE_PATHS();
  const isDiscountFieldEnabled =
    useFeatureFlagsStore(state => {
      return (
        state.featureFlags?.['discount-code-input']?.isEnabled &&
        state.featureFlags?.['discount-code-input']?.payload?.pages?.[ROUTES.MY_QUOTE]
      );
    }) && currentPathname === myQuote;

  return (
    // hidden on large screen
    <div className="sticky bottom-0 flex lg:hidden z-[8]">
      {/* small */}
      <div
        className="bg-mono-100 rounded-t-[36px] w-full bottom-0 sticky top-0 shadow-2xl shadow-fuji-800 flex
     sm:hidden">
        <div className="flex flex-col justify-center flex-1 px-4">
          {isDiscountFieldEnabled && <DiscountSection />}
          <BuyNowButtonSection className="!block" />
        </div>
      </div>
      {/* medium */}
      <div className="hidden sm:flex sm:justify-between bg-mono-100 items-center rounded-t-[36px] w-full px-4 bottom-0 sticky top-0 shadow-2xl shadow-fuji-800">
        <TotalPrice />
        <div className="flex justify-between gap-3">
          {isDiscountFieldEnabled && <DiscountSection />}
          <BuyNowButtonSection className="!block" />
        </div>
      </div>
    </div>
  );
}

const TotalPrice = () => {
  const totalPrice = useTripStore(state => convertCentsToDollars(state?.trip?.price));
  return (
    <div className="flex items-center pr-2">
      <div className="flex items-end gap-2">
        <Text variant="h4-24/sb" className="self-start">
          $
        </Text>
        <Text variant="h2-36/sb">{totalPrice.replace('$', '')}</Text>
        <Text variant="body-16/sb">{i18n.t('footer.sticky.totalLabel')}</Text>
      </div>
    </div>
  );
};

const DiscountSection = () => {
  const [showDiscountInput, toggleDiscountInput] = useState(false);

  if (showDiscountInput === false) {
    return (
      <div className="flex flex-row justify-center sm:flex-1 sm:justify-end">
        <div className="flex pt-6 cursor-pointer" onClick={() => toggleDiscountInput(true)}>
          <Assets.Discount fill="black" height={20} width={20} className="mr-2" />
          <Text variant="subHeading-14/sb" className="underline">
            Got a discount code?
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="flex pt-3 sm:pt-0 sm:items-end sm:pb-3 sm:justify-between sm:w-full">
      <DiscountCodeInput />
    </div>
  );
};
