import {
  ModalConfig,
  ModalParams,
  ModalStore,
  createStore,
  modalStoreGenerator,
} from 'freely-shared-stores';

import { FullScreenModalProps, ModalComponentName, ModalProps } from '@components';

type ModalBodyType = ModalComponentName;
export type _ModalParams = ModalParams<ModalProps | FullScreenModalProps>;

export type _ModalConfig = ModalConfig<ModalProps & FullScreenModalProps>;

export type _ModalStore = ModalStore<ModalProps | FullScreenModalProps, ModalBodyType>;

export const useModalStore = createStore(
  modalStoreGenerator<ModalProps & FullScreenModalProps, ModalBodyType>({
    modals: [],
    uniqueModals: ['RegionGridModal'],
  }),
  {
    name: 'useModalStore',
    shouldPersist: false,
  },
);
