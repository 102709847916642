import { useEffect, useMemo, useState } from 'react';

import {
  getBoostExtraCoverValues,
  getBoostSelectedExtraCover,
  getSelectedExtraCoverPrice,
} from 'freely-shared-utils';

import { EXTRA_CANCELLATION_UNLIMITED_VALUE } from '@packages/constants';

import { i18n } from '@i18n';
import { closeModalSelector, getSelectedBoostById, useModalStore, useTripStore } from '@store';
import { testProps } from '@utils';

import { BoostModalContainer } from '../boostModalContainer';
import { Loader } from '../loader';
import { Text } from '../text';

export type ExtraCancellationModalProps = {
  boostId: string;
};

export function ExtraCancellationModal({ boostId }: ExtraCancellationModalProps) {
  const closeModal = useModalStore(closeModalSelector);
  const addBoost = useTripStore(state => state.addBoost);
  const isPending = useTripStore(state => state.pending);
  const boost = useTripStore(getSelectedBoostById(boostId));
  const selectExtraCancellation = useTripStore(state => state.selectExtraCancellation);
  const setAddedSelectedCover = useTripStore(state => state.setAddedSelectedCover);

  const extraCoverValues = useMemo(() => getBoostExtraCoverValues(boost), [boost]);

  const selectedExtraCover = getBoostSelectedExtraCover(boost);
  const defaultValue =
    selectedExtraCover && extraCoverValues ? extraCoverValues.indexOf(selectedExtraCover) : 0;
  const maxIndex = extraCoverValues ? extraCoverValues?.length - 1 : 0;
  const [extraCoverValuesIndex, setExtraCoverValuesIndex] = useState(
    defaultValue > -1 ? defaultValue : 0,
  );

  /**
   * Extra-cancellation api does not provide a starting price and must need a boost property added to
   * boost in trip in order to get starting price.
   * So we must make an api call to add the property to boost.
   */
  useEffect(() => {
    const getStartingPrice = async () => {
      if (extraCoverValues && !selectedExtraCover && boost?.boostId) {
        await selectExtraCancellation({ boostId: boost.boostId, newValue: extraCoverValues[0] });
      }
    };
    getStartingPrice();
  }, [boost?.boostId, extraCoverValues, selectExtraCancellation, selectedExtraCover]);

  const increaseExtraCoverValue = async () => {
    if (extraCoverValuesIndex === maxIndex || !extraCoverValues || !boost?.boostId) {
      return;
    }
    setExtraCoverValuesIndex(prevState => prevState + 1);
    await selectExtraCancellation({
      boostId: boost?.boostId,
      newValue: extraCoverValues[extraCoverValuesIndex + 1],
    });
  };

  const decreaseExtraCoverValue = async () => {
    if (!extraCoverValuesIndex || !extraCoverValues || !boost?.boostId) {
      return;
    }
    setExtraCoverValuesIndex(prevState => prevState - 1);
    await selectExtraCancellation({
      boostId: boost?.boostId,
      newValue: extraCoverValues[extraCoverValuesIndex - 1],
    });
  };

  const onSubmit = async () => {
    if (!boost?.boostId) {
      return;
    }
    const updatedBoost = await addBoost({ boostId: boost?.boostId, updatePayload: {} });
    const updatedSelectedCover = getBoostSelectedExtraCover(updatedBoost);
    const updatedSelectedCoverPrice = getSelectedExtraCoverPrice(updatedBoost);
    if (updatedSelectedCover && updatedSelectedCoverPrice) {
      setAddedSelectedCover({
        selectedCover: updatedSelectedCover,
        price: updatedSelectedCoverPrice as number,
      });
    }
    closeModal();
  };
  //Todo: Extra Cancellation has different button styles from our style guide. Confrim it.

  return (
    <BoostModalContainer
      isBoostModalFooterDisabled={!!isPending}
      boost={boost}
      onSubmit={onSubmit}
      titleProps={{
        children: i18n.t('boostModals.extraCancellation.title'),
      }}>
      <div>
        <Text variant="body-16/r" className="mt-5 mb-8">
          {i18n.t('boostModals.extraCancellation.subtitle')}
        </Text>
        <div className="md:mb-30 mb-20 flex  w-full  flex-row md:w-3/5">
          <button
            {...testProps('decrease-extra-cover-value-button')}
            disabled={!!isPending}
            onClick={decreaseExtraCoverValue}
            type="button"
            className="mr-1 w-[20%]  rounded-l-lg py-2 bg-cabo-50 disabled:text-fuji-800/50 disabled:bg-cabo-50/60 hover:bg-cabo-50/80">
            <Text variant="h3-28/sb">-</Text>
          </button>
          <div className="bg-cabo-50  flex w-[60%] items-center justify-center">
            {isPending ? (
              <Loader />
            ) : (
              <Text variant="h3-28/sb">
                {extraCoverValues?.[extraCoverValuesIndex] === EXTRA_CANCELLATION_UNLIMITED_VALUE
                  ? 'Unlimited'
                  : `$${extraCoverValues?.[extraCoverValuesIndex] ?? 0}`}
              </Text>
            )}
          </div>
          <button
            {...testProps('increase-extra-cover-value-button')}
            disabled={!!isPending}
            type="button"
            onClick={increaseExtraCoverValue}
            className="ml-1  w-[20%]  rounded-r-lg py-2 bg-nusa-200 disabled:text-mono-100 disabled:bg-mono-300 hover:bg-nusa-100">
            <Text variant="h3-28/sb">+</Text>
          </button>
        </div>
      </div>
    </BoostModalContainer>
  );
}
