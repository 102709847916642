import { useCallback } from 'react';

import { departureDaysOffsetSelector, useConfigStore } from 'freely-shared-stores';

import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { useBoostDetailsStore, useModalStore } from '@store';

const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
export function usePreTripCancellationModal() {
  const departureDaysOffsetRules = useConfigStore(departureDaysOffsetSelector) ?? 10;
  const openModal = useModalStore(state => state.openModal);

  return useCallback(
    (boostId: string) =>
      openModal('Modal', {
        titleText: i18n.t('boostModals.preTripCancellationModal.title'),
        bodyTextProps: {
          children: i18n.t('boostModals.preTripCancellationModal.content', {
            departureDaysOffsetRules,
          }),
        },
        actions: [
          {
            title: i18n.t('boostModals.preTripCancellationModal.actions.ok'),
            variant: 'primary',
            onClick: async () => {
              await getBoostDetails(boostId);
              router.navigate(ROUTE_PATHS().boostDetails(boostId));
            },
          },
        ],
        size: 'md',
        bodyContainerClassName: 'p-8 pb-4',
        titleClassName: '',
        sendExitEvent: true,
      }),
    [openModal, departureDaysOffsetRules],
  );
}
