import { useCallback, useEffect } from 'react';

import {
  getEmcBoost,
  getEmcRegionsFromDestinations,
  getVeriskConfig,
  getVeriskUserConfig,
} from 'freely-shared-utils';

import { EmcNeedHelp } from '@components';
import { useEmcTraveller } from '@hooks';
import {
  closeModalSelector,
  getTrip,
  openModalSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useModalStore,
  useTripStore,
} from '@store';
import { sendAnalyticsEvent } from '@utils';

export const EmcAssessment = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);
  const { emcCurrentTraveller } = useEmcTraveller();

  const regionIds = useTripStore(state => getEmcRegionsFromDestinations(state.trip.destinations));

  const tripDuration = useTripStore(state => getEmcBoost(state?.trip))?.duration;

  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);

  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const removeNameChangedTraveller = useEmcStore(state => state.removeNameChangedTraveller);

  const veriskConfig = encodeURIComponent(
    JSON.stringify(getVeriskConfig(import.meta.env.VITE_ENV as 'dev' | 'staging' | 'prod')),
  );

  const veriskUserConfig = encodeURIComponent(
    JSON.stringify(
      getVeriskUserConfig({
        age: emcDeclaring?.age,
        tripDuration,
        regionIds,
        env: import.meta.env.VITE_ENV,
      }),
    ),
  );

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch {
        data = {};
      }
      if (data.on === 'finish') {
        if (data.result) {
          const result = JSON.parse(decodeURIComponent(data.result));
          await saveScreeningResult({
            isAdded: emcCurrentTraveller?.isAdded ?? false,
            isSelected: true,
            result,
          });
          removeNameChangedTraveller(emcDeclaring?.travellerId ?? '');
          await getTrip();
        }
        if (data.type === 'COMPLETE_SCREENING') {
          setOpenEmcSection('screening-result');
        }
        return;
      }

      if (data.on === 'cancel') {
        openModal('FullScreenModal', {
          body: {
            type: 'EMCCancel',
            // No, go back
            primaryAction: () => {
              closeModal();
            },
            // Yes, cancel
            secondaryAction: () => {
              closeModal();
              setOpenEmcSection(undefined);
            },
          },
        });
      }
    },
    [
      emcCurrentTraveller?.isAdded,
      removeNameChangedTraveller,
      emcDeclaring?.travellerId,
      setOpenEmcSection,
      openModal,
      closeModal,
    ],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    sendAnalyticsEvent('Medical assessment started', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  }, [emcDeclaring?.travellerId]);

  return (
    <div>
      <iframe
        src={`${window.location.protocol}//${window.location.host}/emc/index.html?veriskConfig=${veriskConfig}&veriskUserConfig=${veriskUserConfig}`}
        height="100%"
        width="100%"
        className="w-full h-screen"
        title="emc-widget"
      />
      <div className="flex flex-col items-center">
        <EmcNeedHelp className="sm:hidden mb-8" />
      </div>
    </div>
  );
};
