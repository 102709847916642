import { ChangeEvent, HTMLAttributes, ReactNode, createContext, useContext, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { SelectOption } from '@packages/types';

import { Input, InputProps } from '@elements/input';
import { Loader } from '@elements/loader';
import { Text } from '@elements/text';
import { Combobox } from '@headlessui/react';
import { sluggify, testProps } from '@utils';
import { Button, ButtonProps } from '@elements/button';

const DROPDOWN_OPTION_HEIGHT = 40; // height of each option including inner padding
const DROPDOWN_OPTION_BOTTOM_SPACE = 8; // margin bottom of each option
const DROPDOWN_OPTION_CONTAINER_TOP_BOTTOM_SPACE = 24; // main container padding top and bottom i.e. s12 * 2

export type DropdownProps = {
  items: SelectOption[];
  onSelect: (value: unknown) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  inputProps?: InputProps;
  initialValue?: SelectOption;
  disabled?: boolean;
  optionsHeader?: ReactNode;
  isLoading?: boolean;
  numberOfItems?: number;
  customOptions?: ReactNode;
  hideSelectedLabel?: boolean;
  dropdownMaxHeight?: number;
} & HTMLAttributes<HTMLDivElement>;

const dropdownOptionsStyle = tv({
  base: 'absolute z-50 w-full overflow-auto rounded-2xl bg-surface-primary p-s12 mt-s8 shadow-bottom',
});

type DropdownContextType = DropdownProps & {
  filteredItems: SelectOption[];
  query: string;
  setQuery: (query: string) => void;
};

const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType);
export const Dropdown = (props: DropdownProps) => {
  const {
    items,
    onBlur,
    disabled,
    inputProps,
    initialValue,
    className,
    numberOfItems = 5,
    dropdownMaxHeight,
    onSelect,
    hideSelectedLabel = false,
  } = props;
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(initialValue ?? null);
  const dropdownHeight =
    dropdownMaxHeight ??
    numberOfItems * DROPDOWN_OPTION_HEIGHT +
      numberOfItems * DROPDOWN_OPTION_BOTTOM_SPACE +
      DROPDOWN_OPTION_CONTAINER_TOP_BOTTOM_SPACE;

  const filteredItems =
    query === ''
      ? items
      : items.filter(item => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
  return (
    <DropdownContext.Provider value={{ ...props, filteredItems, query, setQuery }}>
      <Combobox
        onBlur={onBlur}
        disabled={disabled}
        as="div"
        className={twMerge('relative', className)}
        value={selectedItem}
        onChange={(data?: SelectOption) => {
          if (!data) return;
          setQuery('');
          setSelectedItem(data);
          onSelect(data.value);
        }}>
        <Combobox.Input
          as={Input}
          autoComplete="off"
          {...inputProps}
          disabled={disabled}
          onChange={event => {
            setQuery(event.target.value);
            inputProps?.onChange?.(event);
          }}
          displayValue={(item: SelectOption) => (hideSelectedLabel ? '' : item?.label)}
          rightIcon={(props: ButtonProps) => <RenderRightIcon {...props} />}
        />
        <Combobox.Options
          style={{ maxHeight: `${dropdownHeight}px` }}
          className={dropdownOptionsStyle()}>
          <OptionHeader />
          <OptionLoader />
          <OptionsList />
          <CustomOption />
        </Combobox.Options>
      </Combobox>
    </DropdownContext.Provider>
  );
};

Dropdown.Button = Combobox.Button;
Dropdown.Option = Combobox.Option;

const OptionHeader = () => {
  const { optionsHeader } = useContext(DropdownContext);
  if (!optionsHeader) return null;
  return <Combobox.Option value={null}>{optionsHeader}</Combobox.Option>;
};

const OptionLoader = () => {
  const { isLoading } = useContext(DropdownContext);
  if (!isLoading) return null;
  return (
    <Combobox.Option value={null}>
      <Loader className="mx-auto flex items-center justify-center w-s32 text-icon-brand-1" />
    </Combobox.Option>
  );
};

const dropdownOptionStyle = tv({
  base: 'py-s8 px-s12 mb-s8 last:mb-0 cursor-pointer hover:bg-surface-action-selected rounded-md border border-transparent',
  variants: {
    selected: {
      true: 'border-border-action-active bg-surface-action-selected',
    },
  },
});

const OptionsList = () => {
  const { isLoading, customOptions, filteredItems } = useContext(DropdownContext);
  if (filteredItems.length < 1 && !isLoading)
    return (
      <Text variant="body-16/r" className="px-s12 py-s8">
        No results found
      </Text>
    );

  if (customOptions) return null;

  return (
    <>
      {filteredItems.map(item => (
        <Combobox.Option
          key={item.id}
          value={item}
          className={({ selected }) => dropdownOptionStyle({ selected })}>
          <Text
            {...testProps(`search-country-destination-${sluggify(item.label)}`)}
            variant="body-16/r"
            className="truncate text-body text-left">
            {item.label}
          </Text>
        </Combobox.Option>
      ))}
    </>
  );
};

const RenderRightIcon = (props: ButtonProps) => {
  const { inputProps } = useContext(DropdownContext);

  if (typeof inputProps?.rightIcon === 'function') {
    const RightIconComponent = inputProps.rightIcon;

    if (RightIconComponent.name === 'DropdownButton') {
      return <Combobox.Button as={Button} icon="arrow_drop_down" {...props} />;
    }

    if (RightIconComponent.name === 'DropdownClearButton') {
      return <DropdownClearButton icon="cancel" {...props} />;
    }
    return <RightIconComponent {...props} />;
  }
  return <>{inputProps?.rightIcon}</>;
};

const DropdownClearButton = (props: ButtonProps) => {
  const { query, setQuery } = useContext(DropdownContext);
  if (query.length < 1) return null;

  return (
    <Button
      icon="cancel"
      {...props}
      onClick={() => {
        setQuery('');
      }}
    />
  );
};
Dropdown.ClearButton = DropdownClearButton;

const DropdownButton = (props: ButtonProps) => {
  return <Combobox.Button as={Button} {...props} icon="arrow_drop_down" />;
};
Dropdown.Button = DropdownButton;

const CustomOption = () => {
  const { customOptions } = useContext(DropdownContext);
  if (!customOptions) return null;
  return <>{customOptions}</>;
};
