import { CreateStore, createStore } from 'freely-shared-stores';

export interface Toast {
  id: string;
  message: string;
}

export interface ToastState {
  toasts: Toast[];
}

export interface ToastActions {
  addToast: (toast: Toast) => void;
  removeToast: (id: string) => void;
  removeInitialToast: () => void;
}

export type ToastStore = ToastState & ToastActions;

const initialState: ToastState = {
  toasts: [],
};

const toastStore: CreateStore<ToastStore> = set => ({
  ...initialState,
  addToast: (toast: Toast) => {
    set(state => {
      if (!state.toasts.find(({ id }) => toast.id === id)) {
        state.toasts.unshift(toast);
      }
    });
  },
  removeToast: (id: string) => {
    set(state => {
      state.toasts = state.toasts.filter(toast => toast.id !== id);
    });
  },
  removeInitialToast: () => {
    set(state => {
      state.toasts.length > 0 && state.toasts.pop();
    });
  },
});

export const useToastStore = createStore(toastStore, {
  name: 'useToastStore',
});
