import { FC } from 'react';

import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { logToSentry, sendAnalyticsEvent, testProps } from '@utils';

import { BoostCoverItems, BoostCoverItemsProps } from '../boostCoverItems';
import { TabView } from '../tabView';

const TAG = 'BoostCoveredAndNotCoveredTab';

interface BoostCoveredAndNotCoveredTabProps extends React.HTMLAttributes<HTMLDivElement> {
  boostId?: string;
  boostCoverItemsProps?: BoostCoverItemsProps;
  bordered?: boolean;
  header?: React.ReactNode;
  inActiveTabClassName?: string;
  onChange?: () => void;
}
export const BoostCoveredAndNotCoveredTab: FC<BoostCoveredAndNotCoveredTabProps> = ({
  boostCoverItemsProps,
  className,
  bordered = true,
  header,
  inActiveTabClassName,
  boostId,
  ...rest
}) => {
  const boostDetails = useBoostDetailsStore(state => {
    const mandatoryBoostId = state?.mandatoryBoostDetails?.boostId;
    return mandatoryBoostId === boostId ? state.mandatoryBoostDetails : state.boostDetails;
  });
  const coveredItems = boostDetails?.template.coverDetails.covered.items ?? [];
  const notCoveredItems = boostDetails?.template.coverDetails.notCovered.items ?? [];
  const pdsLink = boostDetails?.template.pdsLink?.value;

  const onPdsLinkClick = () => {
    if (!pdsLink) {
      return;
    }
    try {
      sendAnalyticsEvent('Read our PDS Clicked');
      window.open(pdsLink, '_blank', 'noopener,noreferrer');
    } catch (e) {
      logToSentry(e as Error, { tag: TAG });
    }
  };

  return (
    <TabView
      {...rest}
      inActiveTabClassName={inActiveTabClassName}
      bordered={bordered}
      items={[
        {
          label: "What's covered",
          key: 'covered',
          children: (
            <div>
              {header}
              <BoostCoverItems
                className={className}
                boostCoverItems={coveredItems}
                isCovered
                isInBorderdTab={bordered}
                {...boostCoverItemsProps}
              />
              {pdsLink && (
                <Button
                  {...testProps('what-is-cover-button')}
                  onClick={onPdsLinkClick}
                  variant="secondary"
                  title={i18n.t('boostDetails.pdsButton')}
                />
              )}
            </div>
          ),
        },
        {
          label: "What's not covered",
          key: 'not-covered',
          children: (
            <div>
              <BoostCoverItems
                className={className}
                boostCoverItems={notCoveredItems}
                isCovered={false}
                isInBorderdTab={bordered}
                {...boostCoverItemsProps}
              />
              {pdsLink && (
                <Button
                  {...testProps('what-is-not-cover-button')}
                  onClick={onPdsLinkClick}
                  variant="secondary"
                  title={i18n.t('boostDetails.pdsButton')}
                />
              )}
            </div>
          ),
        },
      ]}></TabView>
  );
};
