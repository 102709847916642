import { useCallback } from 'react';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';

import { i18n } from '@i18n';
import { openModalSelector, useModalStore } from '@store';
import { FREELY_WEBSITE_ROUTES, FREELY_WEBSITE_URL } from '@types';
import { logToSentry } from '@utils';

const TAG = 'useNavigateToFreely';

/**
 * A hook that navigates to freely website using users region and state as url params
 * It will fallback on freely.me/au if no country is provided.
 */

type RedirectToFreelyWebsiteArgs = {
  route?: FREELY_WEBSITE_ROUTES;
  isRedirect?: boolean;
  showState?: boolean;
};

export function useNavigateToFreely() {
  const openModal = useModalStore(openModalSelector);
  const region = useRegionStore(getRegionSelector);

  const navigateToFreelyWebsite = useCallback(
    ({ route, isRedirect = true, showState = false }: RedirectToFreelyWebsiteArgs = {}) => {
      const country = (region?.country ?? 'AU').toLowerCase();
      const stateParam = showState && region?.state ? `/${region?.state.toLowerCase()}` : '';
      const urlRoute = route ? `/${route}` : '';

      try {
        const urlToUse = `${FREELY_WEBSITE_URL}/${country}${stateParam}${urlRoute}`;
        if (isRedirect) {
          window.location.replace(urlToUse);
          return;
        }

        window.open(urlToUse, '_blank', 'noopener,noreferrer');
        return;
      } catch (e) {
        logToSentry(e as Error, { tag: TAG });
      }
    },
    [region],
  );

  const openRedirectToFreelyModal = useCallback(() => {
    return openModal('Modal', {
      titleText: i18n.t('global.redirectModal.title'),
      bodyTextProps: { children: i18n.t('global.redirectModal.subtitle') },
      size: 'sm',
      actions: [
        { title: i18n.t('global.redirectModal.actions.back'), variant: 'secondary' },
        {
          title: i18n.t('global.redirectModal.actions.exit'),
          onClick: () => navigateToFreelyWebsite(),
          variant: 'primary',
        },
      ],
    });
  }, [openModal, navigateToFreelyWebsite]);

  return { openRedirectToFreelyModal, navigateToFreelyWebsite };
}
