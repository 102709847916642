import { colors } from '@packages/design';
import { convertCentsToDollars } from 'freely-shared-utils';

import { TravellerMedicalConditions } from '@packages/types';

import { Assets } from '@assets';
import { SeeMore, Text } from '@components';
import { Button } from '@elements/button';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { useBoostDetailsStore, useEmcStore } from '@store';

export type MultiConditionsProps = {
  travellerId: string;
  age: number;
  firstName: string;
  lastName: string;
};

export const MultiConditions = ({
  travellerId,
  age,
  firstName,
  lastName,
}: MultiConditionsProps) => {
  const { emcBoost, getEmcTravellerById } = useEmcTraveller();
  const emcTraveller = getEmcTravellerById(travellerId);
  const isEmcAdded = emcTraveller?.isAdded;
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  if (!emcTraveller) {
    return null;
  }

  const handleEmcBoostChange = () => {
    setEmcDeclaring({
      boostId: emcBoost?.boostId ?? '',
      firstName: firstName,
      lastName: lastName,
      travellerId: emcTraveller.travellerId,
      screeningData:
        emcTraveller?.decryptedScreeningResult?.[0]?.ScreeningPath?.[0]?.SystemData?.ScreeningData,
      age,
    });
    setOpenEmcSection('pre-assessment');
  };

  let coveredConditions: TravellerMedicalConditions[] = [];
  let nonCoveredConditions = emcTraveller.medicalConditions;

  if (isEmcAdded) {
    coveredConditions = emcTraveller.medicalConditions.filter(condition => condition.isCovered);
    nonCoveredConditions = emcTraveller.medicalConditions.filter(condition => !condition.isCovered);
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col justify-between flex-1 m-1 sm:items-center sm:flex-row">
        <div className="grid gap-4">
          {coveredConditions.length > 0 && (
            <div className="flex flex-row-reverse justify-self-auto sm:w-max-md sm:flex-row md:items-center">
              <div className="bg-fuji-800 rounded-xl h-[18px] w-[18px] mr-4 ">
                <Assets.CheckCircle
                  fill={colors.nusa[200]}
                  className="h-[20px] w-[20px] ml-[-1px] mt-[-1px]"
                />
              </div>
              <div className="flex flex-col w-full">
                <div>
                  <Text variant="body-16/sb">
                    {i18n.t('emcShared.global.conditions.covered.title')}
                  </Text>
                </div>
                <SeeMore medicalConditionList={coveredConditions} />
              </div>
            </div>
          )}
          {nonCoveredConditions.length > 0 && (
            <div className="flex flex-row-reverse justify-self-auto sm:w-max-md sm:flex-row md:items-center">
              <div className="bg-fuji-800 rounded-xl h-[18px] w-[18px] mr-4 ">
                <Assets.AlertIcon
                  fill={colors.red[300]}
                  className="h-[20px] w-[20px] ml-[-1px] mt-[-1px]"
                />
              </div>
              <div className="flex flex-col w-full">
                <div>
                  <Text variant="body-16/sb">
                    {i18n.t('emcShared.global.conditions.notCovered.title')}
                  </Text>
                </div>
                <SeeMore medicalConditionList={nonCoveredConditions} />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between w-full sm:items-center sm:w-auto mt-2 ms:mt-0">
          <div className="flex flex-row flex-1 w-full h-full pr-4 sm:flex-col sm:w-auto sm:items-end sm:pr-2">
            {isEmcAdded && (
              <div className="flex w-full md:w-auto pr-1">
                <Text variant="body-16/sb">{convertCentsToDollars(emcTraveller?.price)}</Text>
              </div>
            )}
          </div>
          <Button
            variant="primary"
            size="sm"
            className="w-[8rem]"
            onClick={handleEmcBoostChange}
            title={i18n.t('emcShared.global.conditions.button.title')}
          />
        </div>
      </div>
    </div>
  );
};
