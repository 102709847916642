import { fetchUnauthBoostDetailsById } from 'freely-shared-api';
import { GetStore, getRegionState } from 'freely-shared-stores';

import { logToSentry } from '@utils';

import { BoostDetailsStore } from './boost.store';

const TAG = 'boost.api';
export const fetchBoostDetailsById = async (get: GetStore<BoostDetailsStore>, boostId: string) => {
  const { setBoostDetails, setError, setPending } = get();
  const region = getRegionState();
  if (!region) {
    return;
  }
  try {
    setPending(true);
    const boostDetails = await fetchUnauthBoostDetailsById({
      boostId,
      region,
    });

    setBoostDetails(boostDetails);
    return boostDetails;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};
