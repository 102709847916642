import { updateGuestUser } from 'freely-shared-api';
import { GetStore } from 'freely-shared-stores';

import { GuestDetails } from '@packages/types';

import { logToSentry } from '@utils';

import { GuestStore } from './guest.store';

const TAG = 'guest.api';
export const updateGuest = async (get: GetStore<GuestStore>) => {
  const { setError, setPending, setGuest, guest } = get();
  try {
    setPending(true);
    if (!guest) {
      throw new Error('No guest provided');
    }
    const guestResponse = (await updateGuestUser({
      guest,
    })) as GuestDetails;
    setGuest(guestResponse);
    return guestResponse;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};
