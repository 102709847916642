import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { Container } from '@components';
import { i18n } from '@i18n';
import { sluggify, testProps } from '@utils';

import { Text } from '../text';

export const SupportEnquires = () => {
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);

  return (
    <div className="bg-cabo-50 mt-auto w-full p-6">
      <Container className="flex flex-col items-center gap-4 md:flex-row py-6">
        <div className="h-28 w-28 flex-shrink-0">
          <Assets.CustomerSupport />
        </div>
        <div className="flex-grow-0 space-y-4 text-center md:py-8 md:text-left">
          <Text variant="h2-36/sb" {...testProps('support-enquiries-title')}>
            {i18n.t('footer.supportEnquiries.heading')}
          </Text>
          <Text variant="h4-24/m" {...testProps('support-enquiries-description')}>
            {i18n.t('footer.supportEnquiries.description')}
          </Text>
        </div>
      </Container>
      <Container className="px-0 md:px-6">
        <div className="flex flex-col rounded-3xl gap-4 bg-mono-100 p-6 md:m-2 md:grid md:grid-cols-2 md:p-8">
          {regionSpecificConfig?.SUPPORT_ENQUIRY_GROUPS.map((group, i) => (
            <div key={i} className="space-y-4">
              {group.map(({ value, label, url, layout, size = 'md' }) => (
                <div
                  key={value}
                  className={twMerge(
                    'flex gap-2',
                    layout === 'inline' ? 'flex-row items-center' : 'flex-col',
                  )}>
                  {size === 'sm' && !url ? (
                    <Text
                      variant="body-16/r"
                      {...testProps(`support-enquiries-${sluggify(label)}`)}>
                      {label}: {value}
                    </Text>
                  ) : (
                    <>
                      <Text
                        variant="subTitle-20/sb"
                        {...testProps(`support-enquiries-${sluggify(label)}`)}>
                        {label}:
                      </Text>
                      <Text
                        variant="body-16/r"
                        className={twMerge(!!url && 'underline')}
                        {...testProps(`support-enquiries-${sluggify(label)}-details`)}>
                        <a href={url} rel="noreferrer">
                          {value}
                        </a>
                      </Text>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
