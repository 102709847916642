import { FC, useEffect } from 'react';

import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { Text } from '@components';
import { Button } from '@elements/button';
import { useQuoteStore, useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export interface QuoteErrorModalProps {
  children?: React.ReactNode;
}

export const QuoteError: FC<QuoteErrorModalProps> = () => {
  const assistanceNumberConfig =
    useConfigStore(state => state.regionSpecificConfig?.CUSTOMER_SERVICE_OVERSEAS_NUMBER) ??
    '+61 2 8907 5085';
  const setQuoteLoadingType = useQuoteStore(state => state.setQuoteLoadingType);
  const error = useTripStore(state => state.error);
  const errorType = error?.message?.includes('Timeout error') ? 'Timeout' : 'Internal';

  const handleClose = () => {
    sendAnalyticsEvent('Quote API Error Modal Confirm', {
      type: errorType,
    });
    setQuoteLoadingType({ type: 'quoteLoading' });
  };

  useEffect(() => {
    sendAnalyticsEvent('Quote API Error Modal Display', {
      type: errorType,
    });
  }, [errorType]);
  return (
    <div className="flex flex-col gap-8 items-center max-w-md text-center">
      <Assets.FaceFrown />
      <Text variant="h2-36/sb">Oops! Something went wrong getting your quote</Text>
      <Text variant="body-16/m">
        Unfortunately, we couldn’t process your request in time and there was an issue getting you a
        quote.
      </Text>
      <Text variant="body-16/m">
        This seems to be an ongoing problem, please try again later or call our customer services
        team for assistance.
      </Text>

      <div className="flex flex-col w-full md:flex-row my-2 gap-4">
        <a href={`tel:${assistanceNumberConfig.replace(/\s+/g, '')}`} className="w-full">
          <Button variant="primary" title={assistanceNumberConfig} className="w-full" />
        </a>
        <Button variant="secondary" onClick={handleClose} title="Go Back" className="w-full" />
      </div>
    </div>
  );
};
