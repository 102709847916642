import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useConfigStore } from 'freely-shared-stores';

import { openModalSelector, useModalStore } from '@store';

/**
 * Add any other events listeners here
 */
const events = ['mousemove', 'click', 'keypress'];

interface Props {
  showTimeout?: boolean;
  onClose?: () => void;
}

/**
 * timeout functionality to send the user to beginning of quote
 */
export const useTimeout = ({ showTimeout = true, onClose }: Props) => {
  const openModal = useModalStore(openModalSelector);
  const idleSessionTimeoutInMinutes =
    useConfigStore(state => state?.regionSpecificConfig?.RULES.IDLE_SESSION_TIMEOUT_MINUTES) || 30; // default to 30 minutes;
  const idleTimeout = useMemo(
    () => 1000 * 60 * idleSessionTimeoutInMinutes,
    [idleSessionTimeoutInMinutes],
  );
  // timeout event
  const idleEvent = useRef<number>();

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = useCallback(() => {
    if (idleEvent.current) window.clearTimeout(idleEvent.current);

    if (!showTimeout) {
      return;
    }

    idleEvent.current = window.setTimeout(() => {
      // display timeout modal
      openModal('Modal', {
        title: 'Oops. Your session has timed out.',
        titleClassName: 'text-left',
        body: "Looks like your session has expired, we'll need to get you a new quote.",
        size: 'md',
        onClose: () => {
          if (onClose) {
            onClose();
          }
        },
        actions: [
          {
            title: 'Get a new quote',
            variant: 'primary',
            onClick: () => {
              if (onClose) {
                onClose();
              }
            },
          },
        ],
      });
    }, idleTimeout);
  }, [idleTimeout, openModal, onClose, showTimeout]);

  useEffect(() => {
    // listen for events
    for (const e in events) {
      window.addEventListener(events[e], sessionTimeout);
    }
    // clean up listeners
    return () => {
      for (const e in events) {
        window.removeEventListener(events[e], sessionTimeout);
      }
    };
  }, [sessionTimeout]);
};
