import { FC, useEffect } from 'react';

import { Assets } from '@assets';
import { ModalBodyContainer, Text } from '@components';
import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export interface EMCDeclineModalProps {
  primaryAction: () => void;
  secondaryAction: () => void;
}

export const EMCDecline: FC<EMCDeclineModalProps> = ({ primaryAction, secondaryAction }) => {
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);

  const handleDecline = () => {
    primaryAction();
    sendAnalyticsEvent('Medical cover declined', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  };

  useEffect(() => {
    sendAnalyticsEvent('Decline cover modal opened', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  }, []);

  return (
    <ModalBodyContainer
      icon={<Assets.AlertOutline />}
      header={<Text variant="h3-28/sb">{i18n.t('emcShared.global.modal.decline.title')}</Text>}
      description={
        <Text variant="body-16/r">{i18n.t('emcShared.global.modal.decline.description')}</Text>
      }
      buttons={
        <div className="flex flex-col w-full md:flex-row my-2 gap-4">
          <Button
            className="w-full"
            variant="secondary"
            onClick={handleDecline}
            title={i18n.t('emcShared.global.modal.decline.actions.primary')}
          />
          <Button
            className="w-full"
            variant="primary"
            onClick={secondaryAction}
            title={i18n.t('emcShared.global.modal.decline.actions.secondary')}
          />
        </div>
      }
    />
  );
};
