import React from 'react';

import { colors } from '@packages/design';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { useChooseRegion, useNavigateToFreely } from '@hooks';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { testProps } from '@utils';

import { Flag } from '../flag';

const DefaultHeader: React.FC = () => {
  const { checkout } = ROUTE_PATHS();
  const { openRedirectToFreelyModal } = useNavigateToFreely();
  const region = useRegionStore(getRegionSelector);
  const { openRegionModal } = useChooseRegion();
  const shouldShowRegionSelectionButton = !!region?.country && getCurrentPathname() !== checkout;

  return (
    <div className="bg-cabo-50 flex items-center  justify-between px-3 py-4 lg:px-5 lg:py-6">
      <button onClick={openRedirectToFreelyModal} className="lg:[&>svg]:h-12 lg:[&>svg]:w-24">
        <Assets.FreelyPrime fill={colors.fuji[800]} />
      </button>

      {shouldShowRegionSelectionButton ? (
        <button
          {...testProps(`open-region-modal-button-${region.country}`)}
          className="flex items-center"
          onClick={() => openRegionModal()}>
          <Flag country={region.country} className="w-6 md:w-12" />
          <Assets.ChevronDown className="w-5" />
        </button>
      ) : null}
    </div>
  );
};

export default DefaultHeader;
