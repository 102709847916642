import { LegacyRef, createContext, forwardRef, useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { ButtonProps } from '@elements/button';
import { Icon, IconName } from '@elements/icon';
import { Text } from '@elements/text';

export type InputProps = {
  errorMessage?: string;
  disabled?: boolean;
  leftIcon?: IconName;
  rightIcon?: IconName | ((props: ButtonProps) => JSX.Element | null | undefined);
  label?: string;
  forwardedRef?: LegacyRef<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputPropsContext = createContext<InputProps>({});

const InputElement = (props: InputProps) => {
  const {
    className,
    errorMessage,
    disabled,
    placeholder,
    readOnly = false,
    forwardedRef,
    ...rest
  } = props;
  const hasError = Boolean(errorMessage);

  return (
    <InputPropsContext.Provider value={props}>
      <div className={className}>
        <InputLabel />
        <div
          className={inputContainerStyle({
            hasError,
            disabled,
          })}>
          <RenderLeftIcon />
          <input
            placeholder={placeholder ?? 'Placeholder'}
            disabled={disabled}
            ref={forwardedRef}
            readOnly={readOnly}
            className={inputStyle()}
            {...rest}
          />
          <RenderRightIcon />
        </div>
        <InputErrorLabel />
      </div>
    </InputPropsContext.Provider>
  );
};

/* Input Label */
const InputLabel = () => {
  const { label } = useContext(InputPropsContext);
  if (!label) return null;
  return (
    <Text variant="body-16/r" className="mb-s8 text-body text-left">
      {label}
    </Text>
  );
};

/* Input Error Message Label */
const InputErrorLabel = () => {
  const { errorMessage, disabled } = useContext(InputPropsContext);
  if (!errorMessage || disabled) return null;
  return (
    <Text variant="footnote-12/r" className="text-error mt-s8 w-full text-left">
      {errorMessage}
    </Text>
  );
};

/* Render Left Icon */
const RenderLeftIcon = () => {
  const { leftIcon, disabled, errorMessage } = useContext(InputPropsContext);
  const hasError = Boolean(errorMessage);
  if (!leftIcon) return null;
  return <Icon name={leftIcon} className={twMerge(iconStyle({ disabled, hasError }))} size="sm" />;
};

/* Render Right Icon */
const RenderRightIcon = () => {
  const { rightIcon, disabled, errorMessage } = useContext(InputPropsContext);
  const hasError = Boolean(errorMessage);
  if (!rightIcon && !hasError) return null;
  if (rightIcon && typeof rightIcon === 'function') {
    const iconComponent = rightIcon({
      disabled,
      variant: 'tertiary',
      size: 'sm',
    });
    if (!iconComponent) return null;
    return iconComponent;
  }
  const icon = hasError ? 'error' : rightIcon;
  return <Icon name={icon} size="sm" className={twMerge(iconStyle({ disabled, hasError }))} />;
};

export const Input = forwardRef((props: InputProps, ref: LegacyRef<HTMLInputElement>) => (
  <InputElement {...props} forwardedRef={ref} />
));

Input.displayName = 'Input';

const inputContainerStyle = tv({
  base: [
    'ring-1 ring-border-bold flex w-full rounded-full text-body border-0 justify-between gap-s8 items-center bg-surface-primary',
    'focus-within:ring-border-action-focused focus-within:text-body ',
    //change has-[button]:py-[10px] has-[button]:pr-[10px] to button size after adding input size variant in button
    'px-s16 py-s12 has-[button]:py-[10px] has-[button]:pr-[10px]',
  ],
  variants: {
    hasError: {
      true: 'ring-border-error focus-within:ring-border-error',
    },
    disabled: {
      true: 'bg-surface-disabled text-disabled ring-border-bold',
    },
  },
});

const inputStyle = tv({
  base: 'flex-1 outline-none placeholder-shown:text-placeholder disabled:bg-surface-disabled duration-300 transition-all border-0 focus:ring-0 p-0 bg-surface-primary',
});

const iconStyle = tv({
  base: 'duration-300 transition-all text-brand-2',
  variants: {
    hasError: {
      true: 'text-icon-error',
    },
    disabled: {
      true: 'text-icon-brand-2',
    },
  },
});
