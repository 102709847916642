import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { getRegionSelector, isUSRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getEmcBoost, shouldShowBlankDob } from 'freely-shared-utils';

import { GuestDetailsForm, PaymentForm } from '@packages/types';

import { TripStore, useGuestStore, useTripStore } from '@store';

const primaryEmcTravellerNameSelector = (state: TripStore) => {
  const primaryEmcTraveller = getEmcBoost(state.trip)?.boostProperty?.emcData?.find(
    i => i?.travellerId === state?.trip?.userId,
  );

  return {
    firstName: primaryEmcTraveller?.firstName,
    lastName: primaryEmcTraveller?.lastName,
  };
};

const secondaryTravellerNameSelector = (state: TripStore) => {
  const secondaryEmcTraveller = getEmcBoost(state.trip)?.boostProperty?.emcData?.filter(
    i => i?.travellerId !== state?.trip?.userId,
  );

  return secondaryEmcTraveller?.map(i => ({
    sortKey: i?.travellerId,
    firstName: i?.firstName,
    lastName: i?.lastName,
  }));
};

export type CheckoutFormUS = GuestDetailsForm & PaymentForm;
export type CheckoutFormAU = GuestDetailsForm;

export function useInitialiseTravellerDetailsForm() {
  const secondaryTravellers = useTripStore(state => state.trip?.secondaryTravellers, shallow);
  const primaryTraveller = useTripStore(state => state.trip?.primaryTraveller, shallow);
  const region = useRegionStore(getRegionSelector, shallow);
  const guestDetails = useGuestStore(state => state.guest);

  const emcPrimaryTravellerName = useTripStore(primaryEmcTravellerNameSelector, shallow);
  const emcSecondaryTravellerName = useTripStore(secondaryTravellerNameSelector, shallow);
  const isUS = useRegionStore(isUSRegionSelector);

  const defaultValues = {
    ...guestDetails,
    secondaryTravellers: secondaryTravellers?.map(traveller => {
      const guestSecondaryTravellerDetails = guestDetails?.secondaryTravellers?.find(
        st => st.sortKey === traveller.sortKey,
      );

      const emcSecondaryTraveller = emcSecondaryTravellerName?.find(
        i => i.sortKey === traveller.sortKey,
      );
      return {
        ...traveller,
        ...guestSecondaryTravellerDetails,
        dob: shouldShowBlankDob(traveller) ? '' : (traveller.dob ?? ''),
        firstName:
          guestSecondaryTravellerDetails?.firstName ?? emcSecondaryTraveller?.firstName ?? '',
        lastName: guestSecondaryTravellerDetails?.lastName ?? emcSecondaryTraveller?.lastName ?? '',
      };
    }),
    dob: shouldShowBlankDob(primaryTraveller) ? '' : (primaryTraveller?.dob ?? ''),
    marketAttributes: { market: region?.country ?? 'AU', state: region?.state },
    addressCity:
      guestDetails?.addressData?.detail?.address_components?.find(i => i.types.includes('locality'))
        ?.long_name ?? '',
    addressPostCode:
      guestDetails?.addressData?.detail?.address_components?.find(i =>
        i.types.includes('postal_code'),
      )?.long_name ?? '',
    addressState:
      region?.state ??
      guestDetails?.addressData?.detail?.address_components?.find(i =>
        i.types.includes('administrative_area_level_1'),
      )?.short_name ??
      '',
    addressLine1: guestDetails?.addressData?.data?.description?.split(',')[0],
    addressLine2: guestDetails?.addressData?.data?.description?.split(',')[1],
    firstName: guestDetails?.firstName ?? emcPrimaryTravellerName?.firstName ?? '',
    lastName: guestDetails?.lastName ?? emcPrimaryTravellerName?.lastName ?? '',
    ...(isUS && {
      cardDetails: {
        cardNumber: '',
        cardType: undefined,
        expiryDate: '',
        nameOnCard: '',
      },
    }),
  };

  type FormType = typeof isUS extends true ? CheckoutFormUS : CheckoutFormAU;

  const methods = useForm<FormType>({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  return { methods };
}
