import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';
import { EmcNeedHelp, SlideModal, Text } from '@components';
import { StepperBar } from '@components/stepperBar';
import { Button } from '@elements/button';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  closeModalSelector,
  openModalSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useModalStore,
  useTripStore,
} from '@store';
import { logToSentry, sendAnalyticsEvent } from '@utils';
import { EmcAssessment, EmcInformation, EmcPreAssessment, EmcScreeningResult } from '@views';

const messages = i18n.t('emcShared.global.navigationArrow', {
  returnObjects: true,
});

export const EmcSection = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);

  const isInfoIcon = useEmcStore(state => state.isInfoIcon);
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  const { emcDeclaring, emcCurrentTraveller } = useEmcTraveller();

  const openEmcSection = useEmcStore(state => state.openEmcSection);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const previousEmcStep = useEmcStore(state => state.previousEmcStep);
  const getTrip = useTripStore(state => state.getTrip);

  function handleCloseModal() {
    // If the user exits the emc we would need to always reset emc
    if (openEmcSection === 'information' || openEmcSection === 'pre-assessment') {
      setOpenEmcSection(undefined);

      if (openEmcSection === 'information') {
        sendAnalyticsEvent('EMC Information page closed', {
          TravellerID: emcDeclaring?.travellerId ?? '',
        });
      }
      if (openEmcSection === 'pre-assessment') {
        sendAnalyticsEvent('Pre-assessment page closed', {
          TravellerID: emcDeclaring?.travellerId ?? '',
        });
      }
      return;
    }

    if (openEmcSection === 'assessment') {
      if (emcDeclaring?.requireReDeclare) {
        openModal('FullScreenModal', {
          body: {
            type: 'EMCRedeclare',
            secondaryAction: () => {
              closeModal();
            },
            primaryAction: async () => {
              closeModal();
              setOpenEmcSection(undefined);
              return;
            },
          },
        });
      } else {
        openModal('FullScreenModal', {
          body: {
            type: 'EMCCancel',
            primaryAction: () => {
              closeModal();
            },
            secondaryAction: async () => {
              closeModal();
              setOpenEmcSection(undefined);
              return;
            },
          },
        });
      }
    }
    if (openEmcSection === 'screening-result') {
      if (emcDeclaring?.requireReDeclare) {
        setOpenEmcSection(undefined);
      } else {
        openModal('FullScreenModal', {
          body: {
            type: 'EMCDecline',
            primaryAction: async () => {
              if (emcCurrentTraveller?.isAdded) {
                try {
                  await saveScreeningResult({
                    isAdded: false,
                    isSelected: true,
                  });
                  await getTrip();
                } catch (e) {
                  logToSentry(e as Error);
                }
              }
              closeModal();
              setOpenEmcSection(undefined);
              return;
            },
            secondaryAction: () => {
              closeModal();
            },
          },
        });
      }
      sendAnalyticsEvent('Medical Assessment Summary page closed', {
        TravellerID: emcDeclaring?.travellerId ?? '',
      });
    }
  }

  const handleEmcBackButton = () => {
    if (
      openEmcSection === 'screening-result' &&
      emcDeclaring &&
      emcDeclaring?.screeningData === undefined
    ) {
      setEmcDeclaring({
        ...emcDeclaring,
        screeningData:
          emcCurrentTraveller?.decryptedScreeningResult?.[0]?.ScreeningPath?.[0]?.SystemData
            ?.ScreeningData,
      });
    }
    previousEmcStep();
  };

  return (
    <SlideModal>
      {/* TODO: add a shadow to sticky header */}
      <div
        className={twMerge(
          'sticky top-0 left-0 right-0 z-50 bg-mono-100 pb-3 md:pb-0 mx-1 mt-2 md:mt-4 md:ml-28',
        )}>
        <div className="flex justify-end pt-4">
          <Button icon="cancel_filled" variant="tertiary" onClick={handleCloseModal} />
        </div>

        <div className="hidden sm:flex md:flex justify-end pr-12">
          <EmcNeedHelp />
        </div>

        <div className={twMerge(isInfoIcon ? 'hidden' : 'pb-7 md:-ml-20')}>
          <button
            className={twMerge(
              'flex justify-items-start items-center',
              openEmcSection === 'information' ? 'invisible' : '',
            )}
            onClick={handleEmcBackButton}>
            <Assets.ArrowLeftAlt className="mr-2" />
            {openEmcSection && <Text variant="body-16/sb">{messages[openEmcSection]}</Text>}
          </button>
        </div>
        <div className={twMerge(isInfoIcon ? 'hidden' : 'ml-0')}>
          <StepperBar />
        </div>
      </div>
      <div className="mx-1 md:mx-28 mt-6">
        <Subtitle />
        {openEmcSection === 'information' && <EmcInformation />}
        {openEmcSection === 'pre-assessment' && <EmcPreAssessment />}
        {openEmcSection === 'assessment' && <EmcAssessment />}
        {openEmcSection === 'screening-result' && <EmcScreeningResult />}
      </div>
    </SlideModal>
  );
};

function Subtitle() {
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);
  const currentStep = useEmcStore(state => state.openEmcSection);
  const isPreAssessment = currentStep === 'pre-assessment';

  switch (currentStep) {
    case 'pre-assessment':
    case 'assessment':
    case 'screening-result':
      return (
        <>
          <Text variant="subTitle-20/sb">{i18n.t('emcShared.global.coverSubtitle')}</Text>
          <Text variant="body-16/sb" className="pb-9">
            {isPreAssessment
              ? i18n.t('emcShared.global.preAssessmentCheckTitle')
              : i18n.t('emcShared.global.medicalAssessmentTitle')}
            <span className="text-red-500">
              {emcDeclaring?.firstName} {emcDeclaring?.lastName}{' '}
            </span>
          </Text>
        </>
      );
    default:
      return <Text variant="subTitle-20/sb">{i18n.t('emcShared.information.title')}</Text>;
  }
}
