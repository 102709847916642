import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { useConfigStore, useDestinationsStore, useRegionStore } from 'freely-shared-stores';
import { validateDestinations, validateDestinationsSubmitButton } from 'freely-shared-utils';

import { DestinationsForm, FormOnChange, TripDestination } from '@packages/types';

import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import { captureException, withScope } from '@sentry/react';
import { useTripStore } from '@store';
import { sendAnalyticsEvent, sentryTags } from '@utils';

import { Container } from '../container';
import { DestinationsFooter } from '../destinationsFooter';
import { DestinationsNote } from '../destinationsNote';
import { FooterButtonGroup } from '../footerButtonGroup';
import { GetQuoteProvider } from '../getQuoteProvider';
import { SearchDestination, SelectedDestination, TrendingDestinations } from '../searchDestination';

export type SelectDestinationsProps = {
  onBack: () => Promise<void> | void;
  onNext: () => Promise<void> | void;
  isModal?: boolean;
};

export const SelectDestinations = (props: SelectDestinationsProps) => {
  return (
    <GetQuoteProvider
      defaultFormValues={{ destinations: [] }}
      mode="onChange"
      reValidateMode="onChange">
      <SelectDestinationsContent {...props} />
    </GetQuoteProvider>
  );
};

export const SelectDestinationsContent = ({
  onBack,
  onNext,
  isModal = false,
}: SelectDestinationsProps) => {
  const tripStoreDestinations = useTripStore(state => state.trip?.destinations ?? []);
  // form state
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useFormContext<DestinationsForm>();
  const formTripDestinations = watch('destinations');
  // global state
  const country = useRegionStore(state => state.region?.country);
  const getTripDestinations = useDestinationsStore(state => state.getDestinations);
  const tripDestinations = useDestinationsStore(state => state.destinations);
  const config = useConfigStore(state => state?.regionSpecificConfig);

  const updateTripDestinations = useTripStore(state => state.setTripDestinations);
  const updateUserSearchedDestinations = useDestinationsStore(
    state => state.setUserSearchedDestinations,
  );
  const syncTrip = useTripStore(state => state.syncTrip);
  const { openPriceChangeModal } = usePriceChangeModal();

  useEffect(() => {
    setValue('destinations', tripStoreDestinations);
  }, [setValue, tripStoreDestinations]);

  // local state

  const [isLoading, setIsLoading] = useState(false);
  const maxDestinations = config?.RULES.MAX_DESTINATIONS ?? 10;
  const hasReachedMaxDestinations = formTripDestinations.length >= maxDestinations;

  useEffect(() => {
    if (!tripDestinations && country) {
      withScope(() => {
        sentryTags({ event: 'fetch.destinations', source: 'quote.view.tsx' });
        getTripDestinations().catch(e => captureException(e));
      });
    }
  }, [country, tripDestinations, getTripDestinations]);

  useEffect(() => {
    if (isModal) {
      sendAnalyticsEvent('Modal viewed', { modalTitle: i18n.t('selectDestinations.title') });
    }
  }, [isModal]);

  const sendToAnalytics = useCallback((destination: TripDestination, isAdded: boolean) => {
    const eventName = isAdded ? 'Destination Added' : 'Destination Removed';
    sendAnalyticsEvent(eventName, { destination: destination.longName });
  }, []);

  const handleToggleDestination = useCallback(
    (onFormChange: FormOnChange) => (destination: TripDestination) => {
      const isDestinationSelected = formTripDestinations.find(
        formDestination => formDestination.countryCode == destination.countryCode,
      );

      if (
        (hasReachedMaxDestinations && !isDestinationSelected) ||
        typeof destination !== 'object'
      ) {
        return;
      }

      //if the formTripDestination is empty or new destination does not match, add it
      if (!formTripDestinations || !isDestinationSelected) {
        onFormChange([...formTripDestinations, destination]);
        sendToAnalytics(destination, true);
      } else {
        // remove from user searched destinations
        onFormChange(
          formTripDestinations.filter(dest => dest.countryCode !== destination.countryCode),
        );
        sendToAnalytics(destination, false);
      }
    },
    [formTripDestinations, hasReachedMaxDestinations, sendToAnalytics],
  );

  const onSubmit = async (data: DestinationsForm) => {
    if (isModal) {
      // confirmation modal
      return openPriceChangeModal({
        onNext: async () => {
          setIsLoading(true);
          updateUserSearchedDestinations(formTripDestinations);
          updateTripDestinations(data.destinations);
          onNext();
          await syncTrip();
          setIsLoading(false);
        },
      });
    }

    setIsLoading(true);
    updateUserSearchedDestinations(formTripDestinations);
    updateTripDestinations(data.destinations);
    await onNext();
    setIsLoading(false);
  };

  return (
    <Controller
      name="destinations"
      control={control}
      rules={{
        validate: {
          validateDestinations: v => validateDestinations(v, maxDestinations),
          validateSubmitButton: v =>
            validateDestinationsSubmitButton({
              isModal,
              formTripDestinations: v,
              tripDestinations: tripStoreDestinations,
            }),
        },
      }}
      render={({ field: { onChange } }) => {
        return (
          <Container
            titleText={i18n.t('selectDestinations.title')}
            className="flex flex-col flex-1"
            hasRegionResidencyStatus={country === 'AU' && !isModal}>
            <div>
              <SearchDestination
                selectedDestinations={formTripDestinations}
                onClick={(destination: TripDestination) => {
                  handleToggleDestination(onChange)(destination);
                }}
                hasReachedMaxDestinations={hasReachedMaxDestinations}
              />
            </div>
            <SelectedDestination
              selectedDestinations={formTripDestinations}
              onRemoveSelectedDestination={handleToggleDestination(onChange)}
              hasReachedMaxDestinations={hasReachedMaxDestinations}
            />
            <TrendingDestinations
              selectedDestinations={formTripDestinations}
              onToggleDestination={handleToggleDestination(onChange)}
            />
            <DestinationsNote />
            <hr className="border-gray-200 my-6" />
            <div
              className={twMerge('flex flex-col gap-4', isModal && 'flex-col md:flex-row-reverse')}>
              {isModal ? (
                <>
                  <FooterButtonGroup
                    nextButtonProps={{
                      variant: 'primary',
                      title: i18n.t('selectDestinations.modal.actions.update'),
                      onClick: handleSubmit(onSubmit),
                      disabled: !isValid,
                      isLoading,
                      className: 'w-full',
                    }}
                    backButtonProps={{
                      variant: 'secondary',
                      title: i18n.t('selectDestinations.modal.actions.cancel'),
                      onClick: onBack,
                      className: 'w-full',
                    }}
                  />
                </>
              ) : (
                <DestinationsFooter
                  isLoading={isLoading}
                  onNext={handleSubmit(onSubmit)}
                  onBack={onBack}
                  isNextDisabled={!isValid}
                />
              )}
            </div>
          </Container>
        );
      }}
    />
  );
};
