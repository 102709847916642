import { twMerge } from 'tailwind-merge';
import { shallow } from 'zustand/shallow';

import { useConfigStore, useRegionStore } from 'freely-shared-stores';
import {
  convertCentsToDollars,
  getDurationOfDatesInDays,
  getRegionDateTime,
} from 'freely-shared-utils';

import { Assets } from '@assets';
import { i18n } from '@i18n';
import { useTripStore } from '@store';

import { Text } from '../../text';

export interface AfterPayBannerProps extends React.ComponentProps<'div'> {
  price?: string | number;
  divideBy?: number;
  showTotalPrice?: boolean;
  emptyComponent?: React.ReactNode;
  variant?: 'icon' | 'text';
  hideIcon?: boolean;
}

export const AfterPayBanner = ({
  price,
  divideBy = 4,
  className,
  showTotalPrice,
  variant = 'text',
  emptyComponent = null,
  hideIcon = false,
  ...rest
}: AfterPayBannerProps) => {
  const isFreePolicy = useTripStore(state => !!state?.trip?.promotionCode?.isFreeOfCharge);
  const [afterPayMaxAmountLimit, afterPayEndDateOffset] = useConfigStore(
    state => [
      state.regionSpecificConfig?.RULES?.AFTER_PAY_MAX_AMOUNT_LIMIT,
      state.regionSpecificConfig?.RULES?.AFTER_PAY_TRIP_END_DATE_OFFSET,
    ],
    shallow,
  );
  const region = useRegionStore(state => state.region, shallow);
  const tripEndDate = useTripStore(state => state.trip?.endDate);

  const _price = price ? parseInt(price.toString(), 10) : 0;

  const daysToTripEndDate = getDurationOfDatesInDays(
    getRegionDateTime(region?.country),
    tripEndDate,
    region?.country,
  );

  const isTripEndDateWithinAfterPayEndDateOffset = daysToTripEndDate < (afterPayEndDateOffset ?? 0);

  if (_price / 100 > (afterPayMaxAmountLimit ?? 0) || !isTripEndDateWithinAfterPayEndDateOffset)
    return <>{emptyComponent}</>;

  if (!price || isFreePolicy) return null;
  if (variant === 'icon')
    return (
      <Assets.AfterPay height={29} width={40} className="border-cabo-200 border-1 rounded-lg" />
    );

  return (
    <div
      {...rest}
      className={twMerge(
        'flex justify-center items-center gap-2',
        className,
        showTotalPrice && '!block [&>p]:mb-2',
      )}>
      <Text variant="body-16/m" className="mr-2">
        {i18n.t('checkout.sideBar.payment.afterpay.title')}{' '}
        <strong>{convertCentsToDollars(_price / divideBy)}</strong>{' '}
        {i18n.t('checkout.sideBar.payment.afterpay.titleBold')}
      </Text>
      {!hideIcon && <Assets.AfterPayLogo />}
    </div>
  );
};
