import currency from 'currency.js';

import {
  formatBoostUnitText,
  getBoostSelectedExtraCover,
  getExistingSpecifiedItems,
  isBoostExtraCancellation,
  isBoostSpecifiedItems,
  isTripFreeOfCharge,
} from 'freely-shared-utils';

import { EXTRA_CANCELLATION_UNLIMITED_VALUE } from '@packages/constants';
import { BOOST_CATEGORY, Boost, BoostPriceType, Trip } from '@packages/types';

import { getTripSelector, useTripStore } from '@store';
import { testProps } from '@utils';

import { Text } from '../text';

interface UnitProps {
  boost: Boost;
  type?: BoostPriceType;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const Unit = ({ boost, type }: UnitProps) => {
  const trip = useTripStore(getTripSelector);
  const isBoostCard = type === 'boostCard';

  const unit = calculateBoostUnit({
    boost,
    trip,
    type,
  });

  if (unit && isBoostCard) {
    return (
      <Text variant="footnote-12/sb" className="self-end" {...testProps('boost-price-unit')}>
        {unit?.replace('/', '/ ')}
      </Text>
    ); // e.g. [cost] / day
  }

  if (unit) {
    return (
      <Text variant="body-16/sb" className="mt-1.5">
        {unit}
      </Text>
    );
  }

  return null;
};

interface CalculateBoostUnit {
  boost: Boost;
  trip: Trip;
  type?: BoostPriceType;
}

const BOOST_UNITS = {
  TRIP: 'trip',
  DAY: 'day',
  ITEM: 'item',
  ITEMS: 'items',
  UNLIMITED: 'unlimited',
  LIMIT: 'limit',
} as const;

export const calculateBoostUnit = ({ boost, trip, type }: CalculateBoostUnit) => {
  const isFreeOfCharge = isTripFreeOfCharge(trip);
  const isSpecifiedItems = isBoostSpecifiedItems(boost);
  const isExtraCancellation = isBoostExtraCancellation(boost);
  const isAdded = boost?.toUpdate?.isAdded ?? boost?.isAdded;
  // type
  const isBoostDetails = type === 'boostDetails';
  const isBoostCard = type === 'boostCard';

  /**
   * get unit for pay per day rate
   */

  const isPayPerDay = boost?.category === BOOST_CATEGORY.MY_ACTIVITIES;
  const duration = boost?.toUpdate ? boost?.toUpdate?.duration : boost?.duration;

  // handle unit for boost card
  if (isBoostCard) {
    if (isPayPerDay) {
      return `/${BOOST_UNITS.DAY}` as const;
    }

    if ((isExtraCancellation || isSpecifiedItems) && !isAdded) {
      return null;
    }

    return `/${BOOST_UNITS.TRIP}` as const;
  }

  // all other types
  if (isPayPerDay && isFreeOfCharge) {
    return `/${BOOST_UNITS.TRIP}` as const;
  }

  if (isPayPerDay) {
    return `/${formatBoostUnitText({ amount: duration, unit: 'day' })}` as const;
  }

  /**
   * This is for showing 'Add Items' if the boost is "Specified Item" and there is no price selected.
   */
  const numberOfExistingSpecifiedItems = getExistingSpecifiedItems(boost)?.length ?? 0;
  if (isSpecifiedItems && !isAdded) {
    return `/${BOOST_UNITS.ITEMS}` as const;
  }

  if (isSpecifiedItems && numberOfExistingSpecifiedItems > 0 && isBoostDetails) {
    return `/${formatBoostUnitText({
      amount: numberOfExistingSpecifiedItems,
      unit: 'item',
    })}` as const;
  }

  if (isSpecifiedItems) {
    return `/${BOOST_UNITS.ITEM}` as const;
  }

  /**
   * Handle extra cancellation units
   */
  if (isExtraCancellation && isBoostDetails && !isAdded) {
    return null;
  }

  if (isExtraCancellation) {
    return `/${BOOST_UNITS.TRIP}` as const;
  }

  const selectedCover = getBoostSelectedExtraCover(boost);
  if (isExtraCancellation && typeof selectedCover === 'number' && isBoostDetails) {
    return selectedCover === EXTRA_CANCELLATION_UNLIMITED_VALUE
      ? (`/${BOOST_UNITS.UNLIMITED}` as const)
      : (`/${currency(selectedCover, {
          symbol: '',
          precision: 0,
          separator: ',',
        }).format()} ${BOOST_UNITS.LIMIT}` as const); // e.g. /10,000 limit
  }

  /**
   * catch all case
   */
  return `/${BOOST_UNITS.TRIP}` as const;
};

export const numberOfItemsOrDays = (boost: Boost) => {
  // if items
  const isSpecifiedItems = isBoostSpecifiedItems(boost);
  const numberOfExistingSpecifiedItems = getExistingSpecifiedItems(boost)?.length ?? 0;

  if (isSpecifiedItems && numberOfExistingSpecifiedItems > 0) {
    return `(${formatBoostUnitText({
      amount: numberOfExistingSpecifiedItems,
      unit: 'item',
    })})` as const;
  }

  // if days
  const isPayPerDay = boost?.category === BOOST_CATEGORY.MY_ACTIVITIES;
  const duration = boost?.toUpdate ? boost?.toUpdate?.duration : boost?.duration;
  const value = formatBoostUnitText({ amount: duration, unit: 'day' });

  if (isPayPerDay && value) {
    if (duration === 0) {
      return '';
    }
    return `(${value})` as const;
  }
};
