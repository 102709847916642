import { createContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import {
  formatCardNumberByType,
  getCardBrand,
  getRegionDateTime,
  validate,
  validateCardNumberByType,
  validateExpiryDateWeb,
} from 'freely-shared-utils';

import { MIN_VALID_CREDIT_LENGTH, mmYYFormat } from '@packages/constants';
import { PAYMENT_TYPE } from '@packages/types';

import { CardTypeDropdownComponent } from '@components/paymentUplift/creditCardForm/cardTypeDropdown.component';
import { Button, ButtonProps } from '@elements/button';
import { IconName } from '@elements/icon';
import { Input, MaskedInput } from '@elements/input';
import { CheckoutFormUS } from '@hooks';
import { useCheckoutStore } from '@store';

export const cardIconType: Omit<
  Record<PAYMENT_TYPE, string>,
  'ApplePay' | 'GooglePay' | 'AfterPay' | 'CreditCard'
> = {
  Visa: 'Visa',
  Mastercard: 'Mastercard',
  Discover: 'Discover',
  'American Express': 'Amex',
} as const;

export type CreditCardDropdownProps = {
  isCardDropdownOpen?: boolean;
  handleCardTypeChange: (selectedCard: PAYMENT_TYPE) => void;
  handleBackdropClick: () => void;
  setIsCardDropdownOpen: (isOpen: boolean) => void;
};

export const CreditCardDropdownContext = createContext<CreditCardDropdownProps>(
  {} as CreditCardDropdownProps,
);

export const CreditCardForm = () => {
  const { control, trigger, clearErrors, setValue, watch } = useFormContext<CheckoutFormUS>();

  const creditCardNumber = watch('cardDetails.cardNumber');
  const rawCardNumber = creditCardNumber.replace(/ /g, '');
  const [cardType, setCardType] = useState<PAYMENT_TYPE | undefined>();
  const openSection = useCheckoutStore(state => state.openSection);
  const [isCardDropdownOpen, setIsCardDropdownOpen] = useState(false);

  const region = useRegionStore(getRegionSelector);

  let detectedCardType = (getCardBrand(rawCardNumber) as PAYMENT_TYPE) ?? cardType ?? undefined;
  const formattedCardNumber = formatCardNumberByType(detectedCardType) ?? '#### #### #### #### ###';
  const isCardNumberValid = rawCardNumber.length >= MIN_VALID_CREDIT_LENGTH;

  const handleCardNumberChange = (value: string) => {
    detectedCardType && setValue('cardDetails.cardType', detectedCardType);
    const rawNumber = value.replace(/ /g, '');
    // clear card type if card number is empty
    if (rawNumber.length < 1) {
      setCardType(undefined);
    }
    setValue('cardDetails.cardType', getCardBrand(rawNumber) as PAYMENT_TYPE | undefined);
  };

  const handleCardTypeChange = async (selectedCard?: PAYMENT_TYPE) => {
    setValue('cardDetails.cardType', selectedCard);
    setCardType(selectedCard);
    setIsCardDropdownOpen(false);
    await trigger('cardDetails.cardNumber'); // Trigger validation immediately after selection

    // //clear type error if card number is greater is valid
    if (isCardNumberValid) {
      clearErrors('cardDetails.cardNumber');
    }
  };

  const handleCardClick = () => {
    setIsCardDropdownOpen(prev => !prev);
  };

  const handleBackdropClick = () => {
    setIsCardDropdownOpen(false);
  };

  return (
    <form className="space-y-4 relative">
      <Controller
        control={control}
        name="cardDetails.cardNumber"
        rules={{
          validate: {
            requiredCardNumber: value => {
              if (openSection !== 'payment') {
                return undefined;
              }
              if (!value) {
                return 'Your card number is invalid.';
              }
            },
            manualCardSelectedValidation: value => {
              if (openSection !== 'payment') {
                return undefined;
              }
              const rawNumber = value.replace(/ /g, '');
              // if the cardNumber is pasted and card type is not detected, detect card type
              detectedCardType = detectedCardType ?? (getCardBrand(rawNumber) as PAYMENT_TYPE);

              if (detectedCardType) {
                //if card does not match the length return error
                if (!validateCardNumberByType(rawNumber, detectedCardType)) {
                  return 'Your card number is invalid.';
                }
                return true;
              }

              //if card type is not detected and card number is greater than 13 characters return error to select card type
              if (rawNumber.length >= MIN_VALID_CREDIT_LENGTH) {
                return 'Please select a card type.';
              }

              return isCardNumberValid || 'Your card number is invalid.';
            },
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <MaskedInput
            onPaste={(e: any) => {
              onChange(e.target.value);
              handleCardNumberChange(e.target.value);
            }}
            mask=""
            format={formattedCardNumber}
            errorMessage={error?.message}
            label="Card Number"
            rightIcon={cardDropdown({
              cardType: detectedCardType,
              onCardClick: handleCardClick,
              creditCardNumberLength: value.length,
            })}
            placeholder="0000 0000 0000 0000"
            value={value}
            onBlur={() => {
              // Validate on blur if no errors and card type is defined
              if (!error && cardType) {
                trigger('cardDetails.cardNumber');
              }
            }}
            onChange={e => {
              onChange(e.target.value);
              handleCardNumberChange(e.target.value);
            }}
          />
        )}
      />

      <CreditCardDropdownContext.Provider
        value={{
          isCardDropdownOpen,
          handleCardTypeChange,
          handleBackdropClick,
          setIsCardDropdownOpen,
        }}>
        <CardTypeDropdownComponent />
      </CreditCardDropdownContext.Provider>
      <Controller
        control={control}
        name="cardDetails.expiryDate"
        rules={{
          validate: (v?: string) => {
            if (openSection !== 'payment') {
              return undefined;
            }
            if (!v) {
              return `Your card's expiration date is incomplete.`;
            }
            return validateExpiryDateWeb(
              v,
              getRegionDateTime(region?.country),
              "Your card's expiration date is incomplete.",
              "Your card's expiration date is in the past.",
            );
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <MaskedInput
            format={mmYYFormat}
            errorMessage={error?.message}
            label="Expiration"
            placeholder="MM/YY"
            value={field.value}
            onChange={e => {
              const { value } = e.target;
              const [month, year] = value.split('/');

              const formattedMonth = parseInt(month, 10) > 12 ? `0${parseInt(month, 10)}` : month;

              const newValue = `${formattedMonth}/${year || ''}`;
              field.onChange(newValue);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="cardDetails.nameOnCard"
        rules={{
          validate: {
            name: v => {
              if (openSection !== 'payment') {
                return undefined;
              }
              return validate('cardHolderName', v.trim());
            },
          },
          maxLength: {
            value: 40,
            message: 'Maximum of 40 characters for cardholder name.',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            value={field.value}
            onChange={e => {
              field.onChange(e.target.value);
            }}
            errorMessage={error?.message}
            label="Name on card"
            placeholder="First and last name"
          />
        )}
      />
    </form>
  );
};

const cardDropdown = ({
  cardType,
  onCardClick,
  creditCardNumberLength,
}: {
  cardType?: PAYMENT_TYPE;
  onCardClick?: () => void;
  creditCardNumberLength: number;
}): IconName | ((props: ButtonProps) => JSX.Element) => {
  if (!cardType && creditCardNumberLength > MIN_VALID_CREDIT_LENGTH) {
    const CardDropdownButton = (props: ButtonProps) => (
      <Button
        iconLeft="CreditCardError"
        iconRight="arrow_drop_down"
        {...props}
        className={twMerge(props.className, '!gap-0')}
        onClick={onCardClick}
      />
    );
    CardDropdownButton.displayName = 'CardDropdownButton';

    return CardDropdownButton;
  }
  return cardIconType[cardType as keyof typeof cardIconType] as IconName;
};
