import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';

import { Text } from '../text';

export const AppStoreRating = () => {
  const ratingValue = useConfigStore(state => state.config?.APPSTORE.rating) ?? 0;
  const reviewCount = useConfigStore(state => state.config?.APPSTORE.reviewCount) ?? 1000;

  return (
    <div>
      <div className="flex items-center">
        <Assets.Star height={24} width={24} className="mb-1" />
        <Text variant="subHeading-14/sb" className="px-1 text-mono-100">
          {ratingValue}
        </Text>
        <Text variant="subHeading-14/r" className=" text-mono-100">
          {`based on ${reviewCount}+ reviews`}
        </Text>
      </div>
    </div>
  );
};
