import { INVALID_PROMO_CODE_TITLE } from '@packages/constants';

import { Assets } from '@assets';
import { Container, Text } from '@components';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { useTripStore } from '@store';
import { testProps } from '@utils';

import { FREELY_WEBSITE_ROUTES } from '../../types/constants';

export function ErrorBanner() {
  const { navigateToFreelyWebsite } = useNavigateToFreely();
  const policyStatus = useTripStore(state => state.trip?.policyStatus);
  const tripError = useTripStore(state => state.error);

  let title = '';
  let subtitle = '';

  if (policyStatus === 'ERROR') {
    title = i18n.t('checkout.payment.error.title');
    subtitle = i18n.t('checkout.payment.error.subtitle');
  }

  if (tripError && tripError.cause === INVALID_PROMO_CODE_TITLE) {
    title = tripError.cause;
    subtitle = tripError.message;
  }

  if (!title && !subtitle) {
    return null;
  }

  return (
    <div className="bg-red-500">
      <Container className="bg-red-500 flex w-full flex-row text-mono-100" key={title}>
        <div className="flex flex-col flex-wrap items-start lg:flex-row lg:items-center lg:justify-center gap-1 lg:gap-4 w-full">
          <div className="flex items-center gap-2">
            <Assets.AlertIcon className="fill-mono-100" />
            <Text variant="subTitle-20/sb">{title}</Text>
          </div>
          <Text variant="body-16/r">{subtitle}</Text>
          <button
            {...testProps('payment-error-navigate-back-to-freely-website-link')}
            className="underline text-mono-100 hover:text-mono-100 underline-offset-4"
            onClick={() =>
              navigateToFreelyWebsite({
                route: FREELY_WEBSITE_ROUTES.GET_HELP,
                isRedirect: false,
              })
            }>
            <Text variant="body-16/r">{i18n.t('checkout.payment.error.contact')}</Text>
          </button>
        </div>
      </Container>
    </div>
  );
}
