import { shallow } from 'zustand/shallow';

import { regionDateUtils } from 'freely-shared-stores';
import { utcToDateFormat } from 'freely-shared-utils';

import { Text } from '@components';
import {
  getAddressSelector,
  getEmailSelector,
  getFullNameSelector,
  getPrimaryDOBSelector,
  getSecondaryTravellers,
  getTravellerAge,
  useGuestStore,
} from '@store';

export const TravellerDetailsSummary = () => {
  const fullName = useGuestStore(getFullNameSelector);
  const primaryDOB = useGuestStore(getPrimaryDOBSelector);
  const email = useGuestStore(getEmailSelector);
  const address = useGuestStore(getAddressSelector);
  const secondaryTravellers = useGuestStore(getSecondaryTravellers, shallow);
  const travellerAge = useGuestStore(getTravellerAge);

  return (
    <div className="flex text-left mt-4">
      <div className="space-y-2 w-full">
        <Text variant="body-16/sb" className="inline pr-[0.2rem]">
          Primary Traveller
        </Text>
        <Text className="text-fuji-300 inline" variant="body-16/r">
          (Age {travellerAge})
        </Text>
        <Text variant="body-16/r">{fullName}</Text>
        <Text variant="body-16/r" className="break-all">
          {email}
        </Text>
        <Text variant="body-16/r">{primaryDOB}</Text>
        <Text variant="body-16/r">{address}</Text>

        {secondaryTravellers?.map((traveller, index) => {
          const firstName = traveller?.firstName;
          const lastName = traveller?.lastName;
          const dob = traveller?.dob ?? '';

          const travellerFullName = `${firstName} ${lastName}`;
          const travellerDobFormatted = utcToDateFormat(dob, 'AU');
          const travellerAge = dob ? regionDateUtils().getAge(dob) : '';

          return (
            <div className="text-left pt-2" key={traveller.sortKey}>
              <Text variant="body-16/sb" className="inline pr-[0.2rem]">
                Traveller {index + 1}
              </Text>
              <Text className="text-fuji-300 inline" variant="body-16/r">
                (Age {travellerAge})
              </Text>
              <div className="space-y-2">
                <Text variant={'body-16/r'}>{travellerFullName}</Text>
                <Text variant={'body-16/r'}>{travellerDobFormatted}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
