import { useMemo } from 'react';

import {
  AddSpecifiedItemModalProps,
  AddSpecifiedItemsModal,
  BoostCalendarModal,
  BoostCalendarModalProps,
  CovidConsent,
  CovidConsentProps,
  EditTravellers,
  EditTravellersProps,
  EmailQuote,
  EmailQuoteFailed,
  EmailQuoteSuccess,
  ExtraCancellationModal,
  ExtraCancellationModalProps,
  MoreInfoModalBody,
  MoreInfoModalBodyProps,
  ProcessingPaymentModal,
  ProcessingPaymentModalProps,
  ReferralModal,
  ReferralModalProps,
  ScanToDownload,
  SelectDepositDate,
  SelectDepositDateProps,
  SelectDestinations,
  SelectDestinationsProps,
  SelectTravelDates,
  SelectTravelDatesProps,
  TripCostEditor,
  TripCostEditorProps,
  UpSell,
  UpSellProps,
  UsTravelDatesProtectionModal,
} from '@components';

export const modalBodyComponents = {
  SelectTravelDates,
  MoreInfoModalBody,
  SelectDestinations,
  AddSpecifiedItemsModal,
  ExtraCancellationModal,
  BoostCalendarModal,
  ProcessingPaymentModal,
  ReferralModal,
  UsTravelDatesProtectionModal,
  EditTravellers,
  ScanToDownload,
  TripCostEditor,
  UpSell,
  SelectDepositDate,
  CovidConsent,
  EmailQuote,
  EmailQuoteSuccess,
  EmailQuoteFailed,
};

export type ComponentProps = {
  SelectTravelDates: SelectTravelDatesProps;
  MoreInfoModalBody: MoreInfoModalBodyProps;
  SelectDestinations: SelectDestinationsProps;
  AddSpecifiedItemsModal: AddSpecifiedItemModalProps;
  ExtraCancellationModal: ExtraCancellationModalProps;
  BoostCalendarModal: BoostCalendarModalProps;
  ProcessingPaymentModal: ProcessingPaymentModalProps;
  ReferralModal: ReferralModalProps;
  UsTravelDatesProtectionModal: Record<string, unknown>;
  EditTravellers: EditTravellersProps;
  ScanToDownload: Record<string, unknown>;
  UpSell: UpSellProps;
  TripCostEditor: TripCostEditorProps;
  SelectDepositDate: SelectDepositDateProps;
  CovidConsent: CovidConsentProps;
  EmailQuote: Record<string, unknown>;
  EmailQuoteSuccess: Record<string, unknown>;
  EmailQuoteFailed: Record<string, unknown>;
};

export type ModalComponentName = keyof typeof modalBodyComponents;

export type ModalBodyProps<T extends ModalComponentName> = {
  type: T;
} & ComponentProps[T];

export function ModalBody<T extends ModalComponentName>(props: ModalBodyProps<T>) {
  const BodyComponent = useMemo(
    () => (modalBodyComponents?.[props?.type] as React.FC<ComponentProps[T]>) ?? null,
    [props?.type],
  );

  if (!BodyComponent) {
    return null;
  }

  return <BodyComponent {...(props as ComponentProps[T])} />;
}
