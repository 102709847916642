import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { isBoostEligible } from 'freely-shared-utils';

import {
  BoostCoveredAndNotCoveredTab,
  BoostHeader,
  Container,
  SpecifiedItemsList,
} from '@components';
import { useBoostDetailsStore } from '@store';
import { sendAnalyticsECommerce, sendAnalyticsEvent, sendAnalyticsPage } from '@utils';

export const BoostDetail = () => {
  const params = useParams();
  const boostId = params?.id;
  const boostDetails = useBoostDetailsStore(state => {
    const mandatoryBoostId = state?.mandatoryBoostDetails?.boostId;
    return mandatoryBoostId === boostId ? state.mandatoryBoostDetails : state.boostDetails;
  });
  const region = useRegionStore(getRegionSelector);

  useEffect(() => {
    sendAnalyticsECommerce('Product Viewed');
    sendAnalyticsEvent('Boost Selection Page View');
  }, []);

  useEffect(() => {
    sendAnalyticsPage('Trip Product Details Page Viewed', {
      productTitle: boostDetails?.template?.title,
      isMandatory: !!boostDetails?.isMandatory,
      isExtra: !boostDetails?.isMandatory,
    });
  }, [boostDetails?.isMandatory, boostDetails?.template?.title]);
  const isEligible = isBoostEligible(boostDetails, region);

  return (
    <div>
      <BoostHeader />
      <Container className="relative z-10 !-mt-20 !pt-7">
        <BoostCoveredAndNotCoveredTab
          boostId={boostDetails?.boostId}
          header={<SpecifiedItemsList boostId={boostDetails?.boostId} />}
          bordered={false}
          className="px-0 pt-8 lg:grid-flow-col "
          inActiveTabClassName={!isEligible ? 'bg-fuji-800/10' : 'bg-nusa-200'}
          onChange={() => {
            sendAnalyticsEvent('Product details coverage toggled');
          }}
        />
      </Container>
    </div>
  );
};
