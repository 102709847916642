import { HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRegionStore } from 'freely-shared-stores';

import { PaymentMethodIcons as AU, Props as AUProps } from './au/paymentMethodIcons.component';
import { PaymentMethodIcons as US } from './us/paymentMethodIcons.component';

export type PaymentMethodIconsProps = AUProps;

export const PaymentMethodIcons = (props: PaymentMethodIconsProps) => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent<HTMLProps<HTMLDivElement>>({ US, AU });
  return <Component {...props} className={twMerge('flex gap-1 py-4', props.className)} />;
};
