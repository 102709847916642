import { FC } from 'react';

import { useRegionStore } from 'freely-shared-stores';

import { TripCostListItem as US, Props as USProps } from './us/tripCostListItem.component';

type Props = USProps;

export const TripCostListItem: FC<Props> = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent<Props>({ US });
  return <Component {...props} />;
};
