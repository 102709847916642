import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { abandonedCartSchema } from 'freely-shared-utils';

import { EmailQuoteDetails } from '@packages/types';

import { Text } from '@components';
import { Button } from '@elements/button';
import { Input } from '@elements/input';
import { Notification } from '@elements/notification';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEmailQuote, useEmailQuoteModalCSNumberExperiment } from '@hooks';
import { i18n } from '@i18n';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';

type Props = {
  flow?: EMAIL_QUOTE_FLOW_TYPE;
};

export const EmailQuote: React.FC<Props> = ({ flow = EMAIL_QUOTE_FLOW_TYPE.FOOTER }) => {
  const { sendEmailQuote, closeEmailQuoteModal, hasExceedEmailLimit, isEmailQuoteSubmitting } =
    useEmailQuote();
  const { payload } = useEmailQuoteModalCSNumberExperiment();
  const description = i18n.t(
    payload?.csNumberEnabled || flow === EMAIL_QUOTE_FLOW_TYPE.FOOTER
      ? 'emailQuote.sendEmail.description.customerService'
      : 'emailQuote.sendEmail.description.default',
  );
  const firstNameValidationMessage = i18n.t('emailQuote.sendEmail.inputs.firstName.validation');
  const lastNameValidationMessage = i18n.t('emailQuote.sendEmail.inputs.lastName.validation');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EmailQuoteDetails>({
    resolver: zodResolver(
      abandonedCartSchema(firstNameValidationMessage, lastNameValidationMessage),
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: EmailQuoteDetails) => {
    await sendEmailQuote(data);
  };

  useEffect(() => {
    if (hasExceedEmailLimit) {
      reset();
    }
  }, [hasExceedEmailLimit, reset]);

  return (
    <form className="w-full relative md:px-4">
      <Text variant="body-16/r" className="pb-6 text-left whitespace-pre-wrap" withMarkDown>
        {description?.replace(/\n/gi, '&nbsp;\n')}
      </Text>
      {hasExceedEmailLimit && (
        <Notification
          variant="error"
          header={i18n.t('emailQuote.sendEmail.exceededLimitNotification.title')}
          body={i18n.t('emailQuote.sendEmail.exceededLimitNotification.description')}
          className="mb-s12"
        />
      )}
      <div className="flex-col mb-4 space-y-4">
        <Input
          placeholder={i18n.t('emailQuote.sendEmail.inputs.firstName.placeholder')}
          {...register('firstName')}
          autoComplete="given-name"
          errorMessage={errors.firstName?.message}
          disabled={hasExceedEmailLimit}
        />
        <Input
          placeholder={i18n.t('emailQuote.sendEmail.inputs.lastName.placeholder')}
          {...register('lastName')}
          autoComplete="family-name"
          errorMessage={errors.lastName?.message}
          disabled={hasExceedEmailLimit}
        />
        <Input
          type="email"
          placeholder={i18n.t('emailQuote.sendEmail.inputs.email.placeholder')}
          {...register('email')}
          autoComplete="email"
          errorMessage={errors.email?.message}
          disabled={hasExceedEmailLimit}
        />
        <Text variant="footnote-12/r" className="text-left" withMarkDown>
          {i18n.t('emailQuote.sendEmail.agreement')}
        </Text>
      </div>
      <div className={'flex flex-col space-y-3'}>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={hasExceedEmailLimit}
          title={i18n.t('emailQuote.sendEmail.buttonActions.send')}
          isLoading={isEmailQuoteSubmitting}
        />
        <Button
          variant="secondary"
          type="submit"
          onClick={closeEmailQuoteModal}
          title={i18n.t('emailQuote.sendEmail.buttonActions.noThanks')}
        />
      </div>
    </form>
  );
};
