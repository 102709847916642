import React from 'react';
import { twMerge } from 'tailwind-merge';

import { isUSRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getExcessDescription } from 'freely-shared-utils';

import { Popover, Text } from '@components';
import { i18n } from '@i18n';
import { tripExcessAmountSelector, useTripStore } from '@store';
import { testProps } from '@utils';

type Props = {
  className?: string;
};

export const ExcessAmount: React.FC<Props> = ({ className }) => {
  const isUS = useRegionStore(isUSRegionSelector);
  const excessAmount = useTripStore(tripExcessAmountSelector);
  const excessDescription = getExcessDescription({ excessAmount });

  return !isUS ? (
    <div className={twMerge('flex flex-row items-center', className)}>
      <div className="flex flex-row ">
        <Text variant="body-16/sb" {...testProps('benefits-excess-amount')}>
          {i18n.t('myQuote.excess.label', { excessAmount })}
        </Text>
      </div>
      <Popover
        titleProps={{ children: i18n.t('myQuote.excess.title'), withMarkDown: true }}
        contentProps={{ children: excessDescription }}
        buttonProps={{
          variant: 'tertiary',
          icon: 'info',
          className: 'ml-s2',
        }}
      />
    </div>
  ) : null;
};
