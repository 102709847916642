import React from 'react';

import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { useGuestStore } from '@store';

import { Text } from '../text';

export const ScanToDownload: React.FC = () => {
  const guest = useGuestStore(state => state.guest);
  const config = useConfigStore(state => state.config);

  const steps = [
    'Scan the code with your phone camera and download the latest version of Freely.',
    `Use your email ${guest?.email || ''} to access your account.`,
    'Turn on notifications and safety alerts before you depart.',
  ];

  return (
    <div className="space-y-4 pb-4">
      <div className="flex items-center gap-4 px-4">
        <img src={Assets.FreelyAppIcon} className="h-12 w-12 rounded-lg" />
        <Text variant="h2-36/sb">Download Freely</Text>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="mx-auto w-[288px] flex-shrink-0">
          <img src={Assets.FreelyAppQR} alt="freely qr code" className="w-full" />
          <div className="flex w-full justify-between px-4">
            <a href={config?.APP_STORE_LINK} rel="noreferrer">
              <Assets.AppStoreBadge className="h-[39px]" />
            </a>
            <a href={config?.PLAY_STORE_LINK} rel="noreferrer">
              <Assets.GooglePlayBadge className="h-[39px]" />
            </a>
          </div>
        </div>
        <div className="space-y-4 px-4 pt-4 pr-2 text-left md:px-0">
          {steps.map((content, i) => (
            <div key={i}>
              <Text variant="subTitle-20/sb">Step {i + 1}:</Text>
              <Text variant="subTitle-20/m">{content}</Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
