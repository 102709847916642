import currency from 'currency.js';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import camelCase from 'lodash/camelCase';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';

import { countries } from '@packages/constants';

import { Boost } from '../../../../types/src';

export const convertCentsToDollars = (
  amount: number | null | undefined | string,
  hasPlusForPositiveAmount = false,
): string => {
  const convertedAmount = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  const value = !convertedAmount || convertedAmount === 0 ? 0 : convertedAmount;
  const isValuePositive = value > 0;
  const numberSign = value < 0 ? '-' : hasPlusForPositiveAmount && isValuePositive ? '+' : '';
  const currencyOptions: currency.Options = {
    symbol: '$',
    fromCents: true,
    precision: 2,
  };

  // If the cent value if dividable by 100, then we can display the whole dollar
  if (!(value % 100)) {
    return `${numberSign}$${currency(Math.abs(value), currencyOptions).dollars()}`;
  }

  // If the cent value is dividable by 10, it is dollar with a tenth ie 2.10
  // the app displays these numbers to 1 decimal place, so we must remove the last string character
  // as currency does not support formatting money to 1 decimal place
  if (!(value % 10)) {
    return `${numberSign}${currency(Math.abs(value), currencyOptions).format()}`.slice(0, -1);
  }

  // All other cases indicate that the value is in 2 decimal places
  return `${numberSign}${currency(Math.abs(value), currencyOptions).format()}`;
};

export const formatMoney = (value = 0) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const getDurationText = (duration?: number, isCapital?: boolean) => {
  const day = isCapital ? 'Day' : 'day';
  if (!duration || duration === 0) {
    return day;
  }

  if (duration === 1) {
    return `1 ${day}` as const;
  }

  return `${duration} ${day}s` as const;
};

export function getDurationDifferenceText(boost: Boost) {
  const { duration = 0, toUpdate } = boost;
  const newDuration = toUpdate?.duration ?? duration;
  const durationDifference = newDuration - duration;
  if (!durationDifference) {
    return formatBoostUnitText({
      amount: Math.abs(duration),
      unit: 'day',
    });
  }

  const operator = durationDifference > 0 ? '+' : '-';
  const amount = Math.abs(durationDifference);
  return `${operator}${formatBoostUnitText({
    amount,
    unit: 'day',
  })}` as const;
}

export const combineNames = (list: Array<{ name: string } | undefined>, delimiter: string) =>
  list
    .map(item => (item?.name ?? '').trim())
    .filter(name => name)
    .join(delimiter);

export const getTestName = (name: string) => upperFirst(camelCase(name));

export const getDefaultPDSLink = () => 'https://www.freely.me/au/product-disclosure-statement';
export const getDefaultPrivacyStatementLink = () => 'https://www.freely.me/au/privacy-statement';

export const safetyAlertSteps = ['Notifications', 'Location', 'Motion sensors'];

export const formatErrorMessage = (message?: string) => {
  let errorMessage = '';
  const preSignUpMessage = 'PreSignUp failed with error ';
  const defineAuthChallengeMessage = 'DefineAuthChallenge failed with error';

  if (message?.includes(preSignUpMessage)) {
    errorMessage = message.replace(preSignUpMessage, '');
  }
  if (message?.includes(defineAuthChallengeMessage)) {
    errorMessage = message?.replace(defineAuthChallengeMessage, '');
  }
  return errorMessage;
};

export const filterPhoneNumber = (str: string) => str.replace(/\s+/g, '');

export const formatPhoneNumber = (phoneNumber: string, countryCallingCode?: string | null) => {
  try {
    if (countryCallingCode) {
      const country = countries.find(country => country.countryCallingCode === countryCallingCode);

      return parsePhoneNumber(
        `${countryCallingCode} ${phoneNumber}`,
        country?.code as CountryCode,
      ).formatInternational();
    }

    return parsePhoneNumber(phoneNumber).formatNational();
  } catch {
    return `${countryCallingCode ?? ''}${phoneNumber}`;
  }
};

export const formatBSB = (bsb: string, showLastTwoDigitsOnly = false) => {
  const bsbDigits = bsb.replace(/\D/g, '');

  if (bsbDigits.length !== 6) {
    return bsb;
  }

  const formated = bsbDigits.replace(/(\d{3})(\d{3})/, '$1-$2');

  return showLastTwoDigitsOnly ? formated.replace(/[0-9-]{5}/g, 'XXX-X') : formated;
};

export const formatBankAccountNumber = (accountNumber: string, showLastTwoDigitsOnly = false) => {
  const accountNumberDigits = accountNumber.replace(/\D/g, '');

  if (accountNumberDigits.length < 6) {
    return accountNumberDigits;
  }

  const groupSize = accountNumberDigits.length > 6 ? 4 : 3;
  let formattedNumber = '';

  for (let i = 0; i < accountNumberDigits.length; i += groupSize) {
    const segment = accountNumberDigits.slice(i, i + groupSize);

    if (segment.length < groupSize) {
      formattedNumber =
        segment.length === 1 ? formattedNumber.trim() + segment : formattedNumber + segment;
      break;
    }

    formattedNumber += segment + ' ';
  }

  formattedNumber = formattedNumber.trim();

  if (showLastTwoDigitsOnly) {
    for (let i = 0; i < formattedNumber.length - 2; i++) {
      if (formattedNumber[i] !== ' ') {
        formattedNumber = formattedNumber.replace(formattedNumber[i] ?? '', 'X');
      }
    }
  }

  return formattedNumber;
};

export const getGoogleDocViewerUrl = (docUrl: string) =>
  `http://docs.google.com/gview?embedded=true&url=${encodeURIComponent(docUrl)}`;

/**
 * Converts promo code to upperCase and removes all white space
 * @param string
 */
export const formatPromoCode = (string?: string) => (string ?? '').toUpperCase()?.replace(/ /g, '');

/**
 * util function to create screen name by character case
 * e.g. 'SelectTrip' -> 'Select Trip Screen'
 */
export const formatScreenName = (screenName: string) => {
  return screenName.replace(/([A-Z])/g, ' $1').trim() + ' Screen';
};

/**
 * util function to replace all \\n with \n
 * @param content
 */
export const unescapeMessageContent = (content: string) => {
  return typeof content === 'string' && content.includes('\\n')
    ? content.replace(/\\n/g, '\n')
    : content;
};

/**
 * Format file size
 * @param size bytes
 * @returns string
 */
export const formatFileSize = (size: number) => {
  const unit = size < 100000 ? 'KB' : 'MB';
  const value = size < 100000 ? size / 1000 : size / 1000000;

  return round(value, 1) + unit;
};

export const formatTheAmount = (amount: string) => {
  const numericAmount = amount.replace(/[^\d]/g, '');

  if (numericAmount === '') {
    return '';
  }

  return currency(numericAmount, { symbol: '', precision: 0 }).format();
};

export function formatBoostUnitText({
  amount,
  unit,
}: {
  amount?: number;
  unit: 'item' | 'day' | 'Day';
}) {
  if (!amount || amount === 0) {
    return unit;
  }

  if (amount === 1) {
    return `1 ${unit}` as const;
  }

  return `${amount} ${unit}s` as const;
}

export function getStepsArray(steps: number) {
  return [...Array(steps).keys()].map(i => i + 1);
}

export function getSpecifiedItemsDifferenceText(boost: Boost) {
  const updatingSpecifiedItems = boost?.toUpdate?.boostProperty?.specifiedItems ?? [];
  const specifiedItems = boost?.boostProperty?.specifiedItems ?? [];
  const newSpecifiedItems = updatingSpecifiedItems ?? specifiedItems;
  const itemsToUse = newSpecifiedItems.length - specifiedItems.length;
  const operator = itemsToUse >= 0 ? '+' : '-';

  return `${operator}${formatBoostUnitText({
    amount: Math.abs(itemsToUse === 0 ? 1 : itemsToUse),
    unit: 'item',
  })}` as const;
}

export function getTripWideDifferenceText(boost: Boost) {
  const { duration = 0 } = boost;
  const isAdded = boost?.toUpdate?.isAdded ?? boost?.isAdded;
  const operator = isAdded ? '+' : '-';

  return `${operator}${formatBoostUnitText({
    amount: Math.abs(duration),
    unit: 'day',
  })}` as const;
}
