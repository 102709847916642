import { useEffect } from 'react';

import { useConfigStore } from 'freely-shared-stores';
import { createSortKeyByUUID } from 'freely-shared-utils';

import { SelectDestinations } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';
import { sendAnalyticsPage } from '@utils';

export const Destinations = () => {
  const DESTINATION_IMAGE_TAG = useConfigStore(
    state => state.regionSpecificConfig?.MARKETING_IMAGE_TAGS?.destination,
  );

  useEffect(() => {
    sendAnalyticsPage('Destination Page Viewed');
  }, []);

  return (
    <main>
      {DESTINATION_IMAGE_TAG && (
        <img
          src={DESTINATION_IMAGE_TAG?.replace('[OrderID]', createSortKeyByUUID())}
          width="1"
          height="1"
          alt=""
          className="absolute -top-96 -left-96"
        />
      )}
      <SelectDestinations
        onBack={() => router.navigate(-1)}
        onNext={() => router.navigate(ROUTE_PATHS().tripDates)}
      />
    </main>
  );
};
