import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';
import { getDefaultPDSLink } from 'freely-shared-utils';

import { Container, Text } from '@components';
import { Button } from '@elements/button';
import { Notification } from '@elements/notification';
import { TextWithMd } from '@elements/text/textWithMd';
import { i18n } from '@i18n';
import { router } from '@routes/router';
import {
  closeModalSelector,
  useBoostDetailsStore,
  useGlobalStore,
  useModalStore,
  useTripStore,
} from '@store';
import { getAUCovidConsentType, sendAnalyticsEvent, sendAnalyticsPage, testProps } from '@utils';

export type CovidConsentProps = {
  isModal?: boolean;
};

export const CovidConsent = ({ isModal = false }: CovidConsentProps) => {
  const isTripPending = useTripStore(state => state.pending);
  const isBoostDetailsPending = useBoostDetailsStore(state => state.pending);
  const isPending = isTripPending || isBoostDetailsPending;
  const closeModal = useModalStore(closeModalSelector);
  const setIsCovidConsentAgreed = useGlobalStore(state => state.setIsCovidConsentAgreed);
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));

  const covidConsentNotificationDescription =
    tripType === 'domestic'
      ? i18n.t('covidConsent.covidHighlights.domestic.description')
      : i18n.t('covidConsent.covidHighlights.international.description');

  useEffect(() => {
    sendAnalyticsPage('DFAT Page Viewed');
  }, []);

  const onAgreePress = async () => {
    sendAnalyticsEvent('Covid Consent Agree Clicked');
    setIsCovidConsentAgreed(true);
    if (isModal) {
      closeModal();
    }
  };

  const onBackPress = () => {
    sendAnalyticsEvent('Covid Consent Back Clicked');
    router.navigate(-1);
    if (isModal) {
      closeModal();
    }
  };
  return (
    <Container className="my-0 mt-6">
      <Text variant="h2-36/sb" className="md:text-center">
        {i18n.t('covidConsent.header')}
      </Text>

      <div className="md:px-6">
        <Notification
          variant="success"
          header={i18n.t('covidConsent.covidHighlights.title')}
          body={covidConsentNotificationDescription}
          className="my-s20"
        />

        <Text variant="subTitle-20/sb" className="mb-4">
          {i18n.t('covidConsent.benefits.title')}
        </Text>
        <CovidBenefitList />
        <Notification
          variant="info"
          header={props => (
            <TextWithMd {...props}>{i18n.t('covidConsent.notification.title')}</TextWithMd>
          )}
          className="my-s20"
        />
        <div className="mb-2">
          <SpecialExcess />
        </div>
        <div className="mt-4 mb-8">
          <Text withMarkDown variant="body-16/r">
            {i18n.t('covidConsent.terms', {
              PDS_LINK:
                useConfigStore.getState()?.regionSpecificConfig?.PDS?.CURRENT ??
                getDefaultPDSLink(),
            })}
          </Text>
        </div>
      </div>
      <div className="w-full mt-6 border-t border-cabo-200" />
      <div className="flex flex-col md:flex-row-reverse items-center w-full gap-4 py-6">
        <Button
          {...testProps('covid-consent-agree-and-continue-button')}
          isLoading={isPending}
          variant="primary"
          onClick={onAgreePress}
          title={i18n.t('covidConsent.buttonActions.agreeAndContinue')}
          className="w-full"
        />
        <Button
          {...testProps('covid-consent-back-button')}
          variant="secondary"
          onClick={onBackPress}
          title={i18n.t('covidConsent.buttonActions.back')}
          className="w-full"
        />
      </div>
    </Container>
  );
};

const listItems = i18n.t('covidConsent.benefits.listItems', {
  lng: 'en-AU',
  returnObjects: true,
});

function CovidBenefitList() {
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));
  const filteredListItems = listItems.filter(item => item.type === tripType);
  const listStyle = tripType === 'domestic' ? 'list-disc' : 'list-decimal';

  if (!filteredListItems.length) {
    return null;
  }

  return (
    <ul className={twMerge('pl-5', listStyle)}>
      {filteredListItems.map(({ text }, index) => (
        <li key={index} className={listStyle}>
          <Text className="mb-4" variant="body-16/r">
            {text}
          </Text>
        </li>
      ))}
    </ul>
  );
}

const specialExcessContent = i18n.t('covidConsent.specialExcess', {
  lng: 'en-AU',
  returnObjects: true,
  variables: {
    PDS_LINK_CURRENT:
      useConfigStore.getState()?.regionSpecificConfig?.PDS?.CURRENT ?? getDefaultPDSLink(),
  },
});
function SpecialExcess() {
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));
  return <Text withMarkDown>{specialExcessContent[tripType]}</Text>;
}
