/* eslint-disable no-prototype-builtins */
import { print } from 'graphql/language/printer';

export class GraphQLError extends Error {
  errors: object;
  schema: any;
  schemaInput: any;
  code: string;

  static isThrowable(error: Error) {
    return error.hasOwnProperty('errors');
  }

  constructor(name: string, schema: any, input: any, errors: object, ...params: [any]) {
    super(...params);
    this.name = name;
    this.errors = errors;
    this.schema = print(schema);
    this.schemaInput = input;
    this.message = JSON.stringify(errors);
    this.code = name;
  }

  getScope(scope: any) {
    scope.setExtra('name', this.name);
    scope.setExtra('schema', this.schema);
    scope.setExtra('schemaInput', this.schemaInput);
    scope.setExtra('appSyncErrors', this.message);
    return scope;
  }
}
