import { regionDateUtils } from 'freely-shared-stores';
import { getEmcTravellerErrorType } from 'freely-shared-utils';

import {
  Boost,
  EmcDataType,
  GuestDetailsForm,
  SecondaryTraveller,
  SelectOption,
  SpecifiedBoostItem,
  Traveller,
} from '@packages/types';

import { TravellerDeclarationError } from '@types';

/**
 * Maps over travellers in trip and constructs them as select objects to be used
 * in comboBox.
 * @param travellers
 */
export function constructTravellersAsSelectOptions(
  travellers?: Traveller[] | SecondaryTraveller[] | null | undefined,
): SelectOption[] {
  if (!travellers) {
    return [];
  }

  return travellers?.map((it, index) => {
    const primaryLabel = it?.isPrimary ? 'Primary' : '';
    const age = it?.dob ? `(${regionDateUtils().getAge(it?.dob)})` : '';
    return {
      id: it.sortKey ?? '',
      label: `${primaryLabel} Traveller ${!primaryLabel ? index : ''} - ${age}`,
      value: it.sortKey,
    };
  });
}

/**
 * Gets the travellers label by mapping the travellerId of the specifiedBoost to the Travellers SortKey
 * If it is primary traveller, it will display a primary tag.
 * All other travellers will be numbered.
 * @param specifiedItem
 * @param specifiedItemBoost
 */
export function getTravellerLabel(
  specifiedItem: SpecifiedBoostItem,
  specifiedItemBoost?: Boost,
): string {
  const secondaryTravellers = specifiedItemBoost?.travellers;
  const travellerInTrip = secondaryTravellers?.find(
    (it: Traveller) => it.sortKey === specifiedItem.travellerId,
  );
  const primaryLabel = travellerInTrip?.isPrimary ? 'Primary' : '';
  const age = travellerInTrip?.dob ? `(${regionDateUtils().getAge(travellerInTrip?.dob)})` : '';
  const travellerIndex = secondaryTravellers?.findIndex(
    it => it.sortKey === specifiedItem.travellerId,
  );
  const travellerIndexLabel = travellerInTrip?.isPrimary
    ? ''
    : `${travellerIndex && travellerIndex > -1 ? travellerIndex : ''}`;
  return `${primaryLabel} Traveller ${travellerIndexLabel} ${age}`;
}

/**
 * calculate total price of all emc boosts
 *
 * @param emcList
 */
export function calculateEmcBoostPrice(emcList: EmcDataType[]) {
  let totalPrice = 0;
  emcList.forEach(item => {
    totalPrice += Number(item.price);
  });
  return totalPrice;
}

export function validateEmcTravellers(
  emcBoost: Boost | undefined,
  guestDetailsForm?: GuestDetailsForm,
) {
  return (emcBoost?.travellers ?? []).map(it => {
    const emcData = emcBoost?.boostProperty?.emcData?.find(
      ({ travellerId }) => travellerId === it.sortKey,
    );

    const guestPrimaryTraveller = {
      firstName: emcData?.firstName ?? guestDetailsForm?.firstName,
      lastName: emcData?.lastName ?? guestDetailsForm?.lastName,
      dob: guestDetailsForm?.dob,
    };

    const guestTraveller =
      (guestDetailsForm?.secondaryTravellers ?? [])?.find(guest => guest.sortKey === it.sortKey) ??
      guestPrimaryTraveller;

    if (!guestTraveller?.firstName || !guestTraveller?.lastName || !guestTraveller?.dob) {
      return {
        travellerId: it?.sortKey ?? '',
        errorType: 'NO_DATA',
      } satisfies TravellerDeclarationError;
    }

    if (!emcData) {
      return {
        travellerId: it?.sortKey ?? '',
        errorType: 'YES_AND_NO_NOT_SELECTED',
      } satisfies TravellerDeclarationError;
    }

    return {
      travellerId: it?.sortKey ?? '',
      errorType: getEmcTravellerErrorType(emcData),
    } satisfies TravellerDeclarationError;
  });
}

export function hasTravellerErrors(errors: Array<TravellerDeclarationError>) {
  return errors?.some(it => !!it?.errorType);
}
