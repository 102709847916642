import currency from 'currency.js';
import { useState } from 'react';

import { colors } from '@packages/design';
import { convertCentsToDollars, isBoostSpecifiedItems } from 'freely-shared-utils';

import { SpecifiedBoostItem } from '@packages/types';

import { Assets } from '@assets';
import { useTripStore } from '@store';
import { getTravellerLabel, sendAnalyticsEvent } from '@utils';

import { Loader } from '../loader';
import { Text } from '../text';

type Props = {
  specifiedItem: SpecifiedBoostItem;
  index: number;
};

export function SpecifiedItemListItem({ specifiedItem }: Props) {
  const specifiedItemBoost = useTripStore(state =>
    state.trip.boosts.find(boost => isBoostSpecifiedItems(boost)),
  );
  const removeSpecifiedItem = useTripStore(state => state.removeSpecifiedItem);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const removeItem = async () => {
    if (!specifiedItem.itemId || !specifiedItemBoost) {
      return;
    }

    setIsRemoveLoading(true);
    await removeSpecifiedItem({
      boostId: specifiedItemBoost.boostId,
      itemIdToBeRemoved: specifiedItem.itemId,
    });
    sendAnalyticsEvent('Specified item removed');
    setIsRemoveLoading(false);
  };

  return (
    <div className="flex w-full flex-auto flex-row">
      <div className="border-cabo-200 flex flex-auto flex-col justify-center border-b py-2">
        <div className="flex flex-row items-center justify-between">
          <div>
            <Text variant="body-16/m">{specifiedItem?.itemName ?? ''}</Text>
          </div>
          {isRemoveLoading && <Loader size="sm" />}
          {!isRemoveLoading && (
            <Assets.Delete
              onClick={removeItem}
              className="mt-2 mb-2 h-4 cursor-pointer"
              fill={colors.fuji[300]}
            />
          )}
        </div>
        <Text variant="body-16/r" className="text-fuji-300 mb-1">
          {getTravellerLabel(specifiedItem, specifiedItemBoost)}
        </Text>

        <div className="mb-1 flex flex-row items-center justify-between">
          <Text variant="body-16/r" className="text-fuji-300">
            {currency(specifiedItem.selectedValue, {
              symbol: '$',
              precision: 0,
              separator: ',',
            }).format()}
          </Text>
          <Text variant="body-16/sb">{convertCentsToDollars(specifiedItem?.price)}</Text>
        </div>
      </div>
    </div>
  );
}
