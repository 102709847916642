import {
  BOOST_CATEGORY,
  Boost,
  BoostDetails,
  BoostSheetTable,
  SheetTable,
} from '../../../types/src';

export const myHealthBoostData: Boost = {
  description: 'Overseas Medical and Dental',
  name: 'Overseas Explorer',
  cmName: 'Oversea medical and dental',
  duration: 1,
  userId: 'eade60d0-0f0f-43d0-9862-ccaae4ddee82',
  code: 'OMD',
  template: {
    market: 'AU',
    coverDetails: {
      covered: {
        items: [
          {
            type: 'bold',
            value: 'Snapshot',
          },
          {
            type: 'text',
            value: 'A quick summary of what the Overseas Explore Plan covers you for:',
          },
          {
            type: 'bullet',
            value: [
              'Overseas medical expenses.',
              'Emergency repatriation or evacuation.',
              'If you have you come back to Australia for unforeseen reasons.',
              'If you become unintentionally legally liable to pay compensation.',
              'If you lose your passports or travel documents',
              'If you die or are disabled during your trip.',
            ],
          },
          {
            type: 'table',
            items: [
              {
                value: '$5,000',
                title: 'Cancellation & Amendment',
              },
              {
                value: '$Unlimited',
                title: 'Overseas Medical & Dental',
              },
              {
                value: '$Unlimited',
                title: 'Additional Expenses',
              },
              {
                value: '$1,000',
                title: 'Passport and Travel Documents',
              },
              {
                value: '$25,000',
                title: 'Accidental Death',
              },
              {
                value: '$25,000',
                title: 'Disability',
              },
              {
                value: '$3,000,000',
                title: 'Personal Liability',
              },
            ],
            columnNames: {
              value: 'Limit',
              title: 'Benefits',
            },
          },
          {
            type: 'bold',
            value: '\nExisting Medical Conditions',
          },
          {
            type: 'text',
            value:
              'We automatically include a number of Existing Medical Conditions please read the PDS for a complete list of what is and isn’t covered.',
          },
          {
            type: 'bold',
            value: '\nSome limits apply',
          },
          {
            type: 'text',
            value:
              'Medical and dental will not exceed 12 months from onset of the illness or injury.\n\nLiability may not exceed $25,000 on International Essentials Plan or $10,000 on Domestic Essentials Plan.\n\nLimits, sub-limits, conditions and exclusions apply. Please read our PDS for the full details below.',
          },
          {
            type: 'bold',
            value: '\nFull Details',
          },
          {
            type: 'text',
            value:
              'This is a summary of the additional benefits provided by this Boost. For a really detailed (and we mean really detailed) description, read the PDS.',
          },
        ],
        title: "What's covered",
      },
      notCovered: {
        items: [
          {
            type: 'bold',
            value: 'Snapshot',
          },
          {
            type: 'text',
            value: 'A summary of what’s NOT Covered:',
          },
          {
            type: 'bullet',
            value: [
              'Any medical costs in Australia – whether before or after your trip.',
              'Routine medical or dental treatments.',
              'Continuation of treatment or follow ups for treatment started prior to your journey.',
              'Any issues arising from you transmitting an illness, sickness or disease.',
              'Medical or dental costs after 12 months from onset of the illness or injury.',
              'Cancellation costs for trips starting within 21 days of policy purchase date if you test positive to COVID-19 and cannot travel.',
            ],
          },
          {
            type: 'text',
            value:
              'N.B. Just a snapshot – if you want to know it all, grab a coffee and read that PDS!',
          },
          {
            type: 'bold',
            value: "\nThings we just don't cover",
          },
          {
            type: 'text',
            value:
              'Events arising from existing medical conditions, or your pregnancy past the 24th week.',
          },
          {
            type: 'text',
            value:
              'You doing irresponsible things that could result in loss, theft or damage to property, death, illness or bodily injury.',
          },
          {
            type: 'text',
            value:
              'Stuff that happens due to you being drunk or otherwise intoxicated. (You know what we’re talking about.)',
          },
        ],
        title: "What's not covered :(",
      },
    },
    sortKey: '2022-08-08',
    subTitle:
      "All benefits on this On-Trip Policy have a $100 excess. The excess is the first amount of a claim that we won't pay for under some benefits. It is deducted from your claim if it is approved by us.",
    id: 'OMD',
    title: 'Overseas Explorer',
    version: '2022-08-08',
    templateId: 'OMD',
    preconditionBoostIds: null,
    pdsLink: {
      title: 'See PDS for detailed information',
      value: 'https://d3taykp79ux94z.cloudfront.net/public/au/pds/2022-11-24/pds.pdf',
    },
    description: '',
    requiredCoverAmount: null,
    inclusions: [],
    additionalInformation: {
      heading: 'Additional Information',
      items: [],
    },
  },
  startEndDates: [
    {
      startDate: '2023-03-31',
      endDate: '2023-03-31',
    },
  ],
  boostProperty: {},
  benefits: [
    {
      description: 'Overseas Medical and Dental',
      name: 'Overseas Explorer.',
    },
  ],
  isAdded: true,
  currency: 'AUD',
  toUpdate: null,
  isMandatory: true,
  price: '778',
  isSelected: true,
  category: BOOST_CATEGORY.MY_HEALTH,
  version: '2022-08-08',
  tripId: 'trip_1678942310340_0ae2c1ac-9ad3-4f79-8174-4aa2fe4a1f50',
  imageURL: null,
  boostId: 'boost_1678942312850_0161ef4e-a0af-4432-8874-133c02eb9977',
  travellers: null,
};
export const sheetTableItemMockData: SheetTable = {
  columnNames: {
    title: 'Benefits',
    value: 'Limit',
  },
  items: [
    {
      title: 'Luggage',
      value: '$10,000',
      child: {
        title: 'Each specific item',
        value: '$750',
      },
    },
    {
      title: 'Cancellation and Amendments',
      value: '$10,000',
    },

    {
      title: 'Money',
      value: '$500',
    },
    {
      title: 'Gadget',
      value: '$500',
    },
  ],
};

export const boostDetailsSheetTableMockData: BoostSheetTable = {
  type: 'sheet-table',
  title: 'Benefits & Limits',
  tables: [sheetTableItemMockData, sheetTableItemMockData],
};

export const payPerDayBoostMockData: Boost = {
  benefits: [
    {
      description:
        'Going Off-Piste? This covers you for snow skiing, boarding, mobiling, heli-skiing and more.',
      name: 'Snow Sports',
    },
  ],
  boostId: 'boost_1688608512285_9d8efd0b-f47a-41ec-9384-94143e631c1f',
  boostProperty: { payPerDayRate: 1000 },
  category: BOOST_CATEGORY.MY_ACTIVITIES,
  cmName: 'Snow Sports + (Freely)',
  code: 'SSPFREE',
  currency: 'AUD',
  description:
    'Going Off-Piste? This covers you for snow skiing, boarding, mobiling, heli-skiing and more.',
  duration: 6,
  imageURL: 'https://d3taykp79ux94z.cloudfront.net/public/au/boost-images/snow_activities.png',
  isAdded: true,
  isMandatory: false,
  isSelected: true,
  name: 'Snow Sports',
  price: '6000',
  startEndDates: [{ endDate: '2023-07-11', startDate: '2023-07-06' }],
  template: null,
  toUpdate: null,
  travellers: null,
  tripId: 'trip_1688608509512_cce20e9e-de91-4cec-aded-578b636681ff',
  userId: '508a6a67-9bf3-4d73-9f30-2760a3181323',
  version: '2023-05-03',
};

export const boostDetailsMockData: BoostDetails = {
  tripId: 'trip_1692681392346_8d853dc3-a317-4a95-86da-e1be551b2ed1',
  boostId: 'boost_1692681394981_c517ce9d-7058-4103-9040-542e5d3b0898',
  userId: 'ac7ab754-6aa7-496b-9bf4-e2899867d9eb',
  code: 'OMD',
  name: 'Overseas Explorer',
  cmName: 'Oversea medical and dental',
  benefits: [{ name: 'Overseas Medical and Dental', description: 'Overseas Medical and Dental' }],
  price: '6900',
  currency: 'AUD',
  version: '2023-05-03',
  startEndDates: [
    {
      endDate: '2023-08-31',
      startDate: '2023-08-23',
    },
  ],
  duration: 9,
  boostProperty: {},
  isMandatory: true,
  template: {
    pdsLink: {
      title: 'See PDS for detailed information',
      value: 'https://d3taykp79ux94z.cloudfront.net/public/au/pds/2023-05-03/pds.pdf',
    },
    market: 'AU',
    preconditionBoostIds: null,
    version: '2023-05-03',
    requiredCoverAmount: null,
    templateId: 'OMD',
    subTitle:
      "All benefits on this On-Trip Policy have a $250 excess. The excess is the first amount of a claim that we won't pay for under some benefits. It is deducted from your claim if it is approved by us.",
    boostMessage:
      "There are certain things that really should be covered when you go away, so we've grouped them into our Overseas Explorer Plan to make it easier for you. Here is a snapshot of some of the benefits:",
    additionalInformation: {
      heading: 'Additional Information',
      items: [
        {
          title: "Things we just don't cover",
          description:
            'You doing an activity that is an exclusion in the Policy. Stuﬀ that happens due to you being drunk or otherwise intoxicated.',
        },
        {
          title: 'Prices & benefits may change',
          description: 'Quote, benefits or product offered is subject to change at any time.',
        },
        {
          title: 'Special Excess',
          description:
            "If you're diagnosed with COVID-19 on your trip, a special excess of $250 applies for cancellation costs and additional expenses in addition to the $50 standard excess.",
        },
        {
          title: 'Existing Medical Conditions',
          description:
            "We automatically include a number of Existing Medical Conditions, please read our PDS for a complete list of what is and isn't covered.",
        },
        {
          title: 'Full details',
          description:
            'This is a brief summary of the beneﬁts and coverages provided by this Trip Extra or Daily Boost. The full coverage terms and details, including limitations and exclusions, are contained in the insurance policy.',
        },
      ],
    },
    categoryCaption: 'MANDATORY',
    sortKey: '2023-05-03',
    inclusions: [
      {
        description:
          'If you become sick or injured while on your trip, we provide cover for unexpected medical and hospital expenses.',
        iconPath: '/public/au/boost-images/ambulance.svg',
        title: 'Overseas Medical',
        subTitle: '$Unlimited',
      },
      {
        description:
          'If you need to pay for unexpected dental treatment during your trip, we can provide cover for emergency overseas dental expenses.',
        iconPath: '/public/au/boost-images/dental.svg',
        title: 'Emergency Dental',
        subTitle: '$Unlimited',
      },
      {
        description:
          'If you become sick or injured while on your trip, we provide cover for the usual and customary cost of unexpected medical and hospital expenses incurred overseas.',
        iconPath: '/public/au/boost-images/covid.svg',
        title: 'COVID-19 Cover',
        subTitle: '$Unlimited',
      },
      {
        description:
          'If your luggage or travel documents are lost, stolen, or damaged on your trip, we can reimburse you for the cost of replacing or repairing your items.',
        iconPath: '/public/au/boost-images/lugguage.svg',
        title: 'Luggage Cover',
        subTitle: '$10,000',
      },
      {
        description:
          'If your luggage is delayed by a transport provider for more than 12 hours during your trip, we can reimburse you for the costs of several personal items purchased.',
        iconPath: '/public/au/boost-images/lugguage-delay.svg',
        title: 'Luggage Delay',
        subTitle: '$1,000',
      },
      {
        description:
          'If you encounter unexpected accommodation and/or transport costs during your travels because of a covered event, we can provide cover for the additional expenses you incur.',
        iconPath: '/public/au/boost-images/wallet.svg',
        title: 'Additional Expenses',
        subTitle: '$Unlimited',
      },
      {
        description:
          'If you need to unexpectedly amend or cancel your trip for a covered reason, we can reimburse you for the costs of your prepaid, non-refundable travel arrangements.',
        iconPath: '/public/au/boost-images/cancel.svg',
        title: 'Cancellation Cover',
        subTitle: '$5,000',
      },
      {
        description:
          "If you need to return to Australia while you're on your trip because of the sudden serious injury, sickness, disease, or death of your relative or business partner.",
        iconPath: '/public/au/boost-images/world-plane.svg',
        title: 'Resumption of Journey',
        subTitle: '$2,000',
      },
      {
        description:
          "If you become unintentionally legally liable to pay compensation for damage caused to someone else's property or for the injury or death of someone .",
        iconPath: '/public/au/boost-images/legal.svg',
        title: 'Personal Liability',
        subTitle: '$3,000,000',
      },
    ],
    coverDetails: {
      covered: {
        title: "What's covered",
        items: [
          {
            type: 'bold',
            value: 'Snapshot',
          },
          {
            type: 'text',
            value: 'A quick summary of what the Overseas Explorer Plan covers you for:',
          },
          {
            type: 'bullet',
            value: [
              'Unlimited overseas medical expenses.',
              'Emergency repatriation or evacuation.',
              'If you have to come back to Australia for unforeseen reasons.',
              'If you lose your passport or travel documents.',
              'If you die or are disabled during your trip.',
              'If your luggage, personal effects, money, passport or travel documents are lost, stolen or damaged, we will repair, replace or pay you the monetary value of the item, less any depreciation that applies, up to the combined value of AU$10,000. Item limits apply.',
              'If your trip has to be cancelled we will compensate you up to the value of AU$5,000.',
            ],
          },
          {
            title: 'Benefits & Limits',
            type: 'sheet-table',
            tables: [
              {
                columnNames: {
                  title: 'Your Trip',
                  value: 'Cover',
                },
                //type: 'table',
                items: [
                  {
                    title: 'Cancellation and Amendments',
                    value: '$5,000',
                  },
                  {
                    title: 'Travel Delay Expenses',
                    value: '$2,000',
                  },
                  {
                    title: 'Resumption of Journey',
                    value: '$2,000',
                  },
                  {
                    title: 'Special Events',
                    value: '$2,000',
                  },
                ],
              },
              {
                columnNames: {
                  title: 'Your Health',
                  value: 'Cover',
                },
                //type: 'table',
                items: [
                  {
                    title: 'Overseas Medical & Dental',
                    value: '$Unlimited',
                  },
                  {
                    title: 'Accidental Death',
                    value: '$25,000',
                  },
                  {
                    title: 'Disability',
                    value: '$25,000',
                  },
                ],
              },
              {
                columnNames: {
                  title: 'Your Stuff',
                  value: 'Cover',
                },
                //type: 'table',
                items: [
                  {
                    title: 'Luggage',
                    value: '$10,000',
                    child: {
                      title: 'Each specific item',
                      value: '$750',
                    },
                  },
                  {
                    title: 'Luggage Delay',
                    value: '$1,000',
                  },
                  {
                    title: 'Money',
                    value: '$500',
                  },
                  {
                    title: 'Passport & Travel Documents',
                    value: '$1,000',
                  },
                ],
              },
              {
                columnNames: {
                  title: 'Other',
                  value: 'Cover',
                },
                //type: 'table',
                items: [
                  {
                    title: 'Personal Liability',
                    value: '$3,000,000',
                  },
                  {
                    title: 'Additional Expenses',
                    value: '$Unlimited',
                  },
                ],
              },
              {
                columnNames: {
                  title: 'Your Excess',
                  value: 'Cover',
                },
                //type: 'table',
                items: [
                  {
                    title: 'Excess',
                    value: '$250',
                  },
                ],
              },
            ],
          },
          {
            type: 'bold',
            value: '\nExisting Medical Conditions',
          },
          {
            type: 'text',
            value:
              "We automatically include a number of Existing Medical Conditions, please read our PDS for a complete list of what is and isn't covered.",
          },
          {
            type: 'bold',
            value: '\nPrices & benefits may change',
          },
          {
            type: 'text',
            value: 'Quote, benefits or product offered is subject to change at any time.',
          },
          {
            type: 'bold',
            value: '\nSome limits apply',
          },
          {
            type: 'text',
            value:
              'Medical and dental will not exceed 12 months from onset of the illness or injury. Liability may not exceed $25,000 on Overseas Explorer Plan or $10,000 on Domestic Explorer Plan. Limits, sub-limits, conditions and exclusions apply. Please read our PDS for the full details.',
          },
          {
            type: 'bold',
            value: '\nFull details',
          },
          {
            type: 'text',
            value:
              'This is a summary of the additional benefits provided by this Explorer plan. For a really detailed (and we mean really detailed) description, read the PDS.',
          },
        ],
      },
      notCovered: {
        title: "What's not covered",
        items: [
          {
            type: 'bold',
            value: 'Snapshot',
          },
          {
            type: 'text',
            value: "A summary of what's NOT Covered:",
          },
          {
            type: 'bullet',
            value: [
              'Any medical costs in Australia - whether before or after your trip.',
              'Routine medical or dental treatments.',
              'Continuation of treatment or follow ups for treatment started prior to your journey.',
              'Any issues arising from you transmitting an illness, sickness or disease.',
              'Medical or dental costs after 12 months from onset of illness or injury.',
              'Items when they are in use, such as drones, sporting equipment, or financial instruments.',
              'Things that are just neglected or items left unattended in a public place might not be covered either.',
              'Travel Delay, including to Special Events, if caused by an Epidemic or Pandemic outbreak.',
              'Resumption of Journey unless trip is over 23 days and your return happens in the first half.',
            ],
          },
          {
            type: 'bold',
            value: "\nThings we just don't cover",
          },
          {
            type: 'text',
            value:
              'Events arising from pre-existing medical conditions, or your pregnancy. You doing an activity that is an exclusion in the Policy. Stuff that happens due to you being drunk or otherwise intoxicated.',
          },
          {
            type: 'bold',
            value: '\nPrices & benefits may change',
          },
          {
            type: 'text',
            value: 'Quote, benefits or product offered is subject to change at any time.',
          },
          {
            type: 'bold',
            value: '\nFull details',
          },
          {
            type: 'text',
            value:
              'This is a summary of the additional benefits provided by this Explorer plan. For a really detailed (and we mean really detailed) description, read the PDS.',
          },
        ],
      },
    },
    description:
      "All benefits on this On-Trip Policy have a $250 excess. The excess is the first amount of a claim that we won't pay for under some benefits. It is deducted from your claim if it is approved by us.",
    id: 'OMD',
    title: 'Overseas Explorer',
  },
  isAdded: true,
  isSelected: true,
  category: BOOST_CATEGORY.MY_HEALTH,
  description:
    'All benefits on this On-Trip Policy have a $250 excess. The excess is the first amount of a claim that we won’t pay for under some benefits. It is deducted from your claim if it is approved by us.',
  toUpdate: null,
  travellers: null,
  imageURL: null,
};
