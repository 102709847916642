import { FC } from 'react';

import { useRegionStore } from 'freely-shared-stores';

import { DestinationsNote as US } from './us/destinationsNote.component';

export const DestinationsNote: FC = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ US });
  return <Component {...props} />;
};
