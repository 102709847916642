import React from 'react';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getBoostModalType, isBoostAdded, isPayPerDayBoost } from 'freely-shared-utils';

import { Boost } from '@packages/types';

import { BoostPrice, Text, TextProps } from '@components';
import { Button } from '@elements/button';
import { Notification } from '@elements/notification';
import { i18n } from '@i18n';
import { useTripStore } from '@store';
import { sluggify, testProps } from '@utils';

import { BoostModalHeader } from '../boostModalHeader';

function getBoostButtonTextId(boost: Boost) {
  const modalType = getBoostModalType(boost);
  const showEditBoostText = isBoostAdded(boost);
  switch (modalType) {
    case 'extraCancellation':
      return i18n.t('global.actions.save');
    case 'addSpecifiedItems':
      return showEditBoostText
        ? i18n.t('boostModals.specifiedItems.button')
        : i18n.t('boostDetails.extraCancellationModal.actions.addItem');
    default:
      return i18n.t('boostDetails.extraCancellationModal.actions.addTrip');
  }
}

export interface BoostModalContainerProps {
  children?: React.ReactNode;
  boost?: Boost;
  onSubmit: () => Promise<void> | void;
  titleProps?: TextProps;
  isBoostModalFooterDisabled?: boolean;
}

export function BoostModalContainer({
  onSubmit,
  boost,
  children,
  titleProps,
  isBoostModalFooterDisabled = false,
}: BoostModalContainerProps) {
  const region = useRegionStore(getRegionSelector);
  const isBoostUpdating = useTripStore(state => state.isBoostUpdating);
  if (!boost) {
    return null;
  }
  const showPayPerDayNotification = isPayPerDayBoost(boost);
  return (
    <div className="flex flex-1 flex-col pt-4">
      <div className="overflow-y-auto max-h-[70vh] px-4 my-6 no-scrollbar sm:px-8">
        <div>
          <BoostModalHeader boost={boost} />
          {region?.country === 'AU' && showPayPerDayNotification && (
            <Notification
              variant="info"
              header="You can now add multiple dates"
              body="Either select a range of dates you need cover for, or double tap to select any single days"
              className="mt-s8"
            />
          )}
        </div>
        <div>
          <Text
            variant="h2-36/sb"
            {...titleProps}
            className={twMerge('my-2', titleProps?.className)}
          />
          {children}
        </div>
      </div>
      <div className="bg-cabo-50 flex flex-row items-center rounded-b-2xl p-8">
        <div className="flex flex-[5] md:flex-[6]">
          {boost && <BoostPrice boost={boost} type="boostModal" />}
        </div>
        <div className="flex flex-[5] justify-end  text-center md:flex-[4]">
          <Button
            {...testProps(`get-boost-modal-button-${sluggify(boost.name)}-save`)}
            onClick={onSubmit as () => void}
            disabled={isBoostModalFooterDisabled}
            type="submit"
            variant="primary"
            className="w-full"
            isLoading={isBoostUpdating}
            title={getBoostButtonTextId(boost)}
          />
        </div>
      </div>
    </div>
  );
}
