import { colors } from '@packages/design';
import { hasPreCancellationBoostPrice } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Text } from '@components';
import { i18n } from '@i18n';
import { useTripStore } from '@store';

const listItems = i18n.t('covidConsent.content.listItems', {
  returnObjects: true,
  lng: 'en-US',
});

type ListItemsProps = {
  type: 'covered' | 'notCovered';
};

export const ListItems = ({ type }: ListItemsProps) => {
  const isPreTripCancellationEligible = useTripStore(state =>
    hasPreCancellationBoostPrice(state.trip),
  );
  const listItems = getListItems(isPreTripCancellationEligible, type);

  if (!listItems.length) {
    return null;
  }

  return (
    <dl>
      {listItems.map((it, index) => {
        return (
          <ListItem key={index} title={it.title} description={it.description} type={it.type} />
        );
      })}
    </dl>
  );
};

interface ListItemProps {
  title: string;
  description: string;
  type: 'covered' | 'notCovered' | string;
}

const ListItem: React.FC<ListItemProps> = ({ title = '', description = '', type }) => {
  return (
    <dl className="relative mb-6">
      <div className="absolute">
        {type === 'covered' && <Assets.CheckGreen />}
        {type === 'notCovered' && <Assets.CloseCircleLight fill={colors.red[500]} />}
      </div>
      <div className="ml-10">
        <dt>
          <Text variant="body-16/sb">{title}</Text>
        </dt>
        <dd>
          <Text withMarkDown className="text-fuji-800/80" variant="subHeading-14/m">
            {description}
          </Text>
        </dd>
      </div>
    </dl>
  );
};

function getListItems(
  isPreTripCancellationEligible: boolean,
  type: 'covered' | 'notCovered' | string,
) {
  const covidConsentType = isPreTripCancellationEligible ? 'eligible' : 'notEligible';

  return listItems?.filter(
    it => it.type === type && (!it.eligibilityType || it.eligibilityType === covidConsentType),
  );
}
