import { useRegionStore } from 'freely-shared-stores';

import { CovidConsentFooter as US } from './us/covidConsentFooter.component';

export const CovidConsentFooter = () => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ US });
  return <Component />;
};
