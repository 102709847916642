import { CreateStore, createStore } from 'freely-shared-stores';

import { EMAIL_QUOTE_FLOW_TYPE } from '@types';

export interface QuoteState {
  didEmailQuoteShow: boolean;
  emailQuoteLaunchedFrom: EMAIL_QUOTE_FLOW_TYPE | null;
  quoteLoadingType: {
    quoteRetryCount: number;
    type: 'quoteLoading' | 'quoteRetry' | 'quoteError';
  };
}

export interface QuoteActions {
  setDidEmailQuoteShow: (val: boolean) => void;
  setEmailQuoteLaunchedFrom: (val: EMAIL_QUOTE_FLOW_TYPE | null) => void;
  setQuoteLoadingType: (val: {
    quoteRetryCount?: number;
    type?: 'quoteLoading' | 'quoteRetry' | 'quoteError';
  }) => void;
}

export type QuoteStore = QuoteActions & QuoteState;

const quoteInitialState: QuoteState = {
  didEmailQuoteShow: false,
  emailQuoteLaunchedFrom: null,
  quoteLoadingType: {
    quoteRetryCount: 0,
    type: 'quoteLoading',
  },
};

export const quoteStore: CreateStore<QuoteStore> = (set, get) => ({
  ...quoteInitialState,
  setDidEmailQuoteShow: val => {
    set(state => {
      state.didEmailQuoteShow = val;
    });
  },
  setEmailQuoteLaunchedFrom: val => {
    set(state => {
      state.emailQuoteLaunchedFrom = val;
    });
  },
  setQuoteLoadingType: val => {
    const currentVal = get().quoteLoadingType;
    set(state => {
      state.quoteLoadingType = { ...currentVal, ...val };
    });
  },
});

export const useQuoteStore = createStore(quoteStore, {
  name: 'useQuoteStore',
});
