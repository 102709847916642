import { HTMLProps, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { useConfigStore } from 'freely-shared-stores';

import { FREELY_WEB_DEFAULT_PAYMENTS } from '@packages/constants';
import { FreelyPaymentMethod } from '@packages/types';

import { Assets } from '@assets';

export const PaymentMethodIcons = (props: HTMLProps<HTMLDivElement>) => {
  const [isApplePayFeatured, isGooglePayFeatured] = useConfigStore(
    state => [
      !!state?.regionSpecificConfig?.NATIVE_PAYMENTS?.APPLE_PAY,
      !!state?.regionSpecificConfig?.NATIVE_PAYMENTS?.GOOGLE_PAY,
    ],
    shallow,
  );
  const paymentMethods = useMemo(() => {
    const payments: FreelyPaymentMethod[] = [...FREELY_WEB_DEFAULT_PAYMENTS];

    if (isApplePayFeatured) {
      payments.push('ApplePay');
    }

    if (isGooglePayFeatured) {
      payments.push('GooglePay');
    }

    payments.push('Discover');

    return payments;
  }, [isApplePayFeatured, isGooglePayFeatured]);

  return (
    <div {...props}>
      {paymentMethods.map(it => {
        const Icon = Assets[it];
        return <Icon height={29} width={40} key={it} />;
      })}
    </div>
  );
};
