import { createContext, useContext, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { useDestinationsStore } from 'freely-shared-stores';
import { getSearchSuggestions, isSuggestedDestinationSelected } from 'freely-shared-utils';

import { TripDestination } from '@packages/types';

import { Dropdown } from '@elements/dropdown';
import { Icon } from '@elements/icon';
import { sluggify, testProps } from '@utils';

import { Text } from '../text';
import { i18n } from '@i18n';

export interface SearchDestinationProps {
  onClick: (destination: TripDestination) => void;
  selectedDestinations: TripDestination[];
  hasReachedMaxDestinations: boolean;
}

type SearchDestinationContextType = SearchDestinationProps & {
  query: string;
  items: Array<{ id: string; value: TripDestination; label: string }>;
};

const SearchDestinationContext = createContext<SearchDestinationContextType>(
  {} as SearchDestinationContextType,
);

export const SearchDestination = (props: SearchDestinationProps) => {
  const { onClick, hasReachedMaxDestinations } = props;
  const tripDestinations = useDestinationsStore(state => state.destinations);

  const [query, setQuery] = useState('');
  const filteredDestinations = getSearchSuggestions(query, tripDestinations ?? []);

  const items = filteredDestinations.map(destination => ({
    id: destination.countryId,
    value: destination,
    label: destination.longName,
  }));

  return (
    <SearchDestinationContext.Provider value={{ ...props, query, items }}>
      <Dropdown
        items={items}
        onSelect={destination => {
          onClick(destination as TripDestination);
        }}
        dropdownMaxHeight={338}
        inputProps={{
          leftIcon: 'search',
          placeholder: i18n.t('selectDestinations.input.placeholder'),
          readOnly: hasReachedMaxDestinations,
          rightIcon: props => {
            return <Dropdown.ClearButton {...props} />;
          },
          onChange: event => {
            setQuery(event.target?.value);
          },
        }}
        hideSelectedLabel
        customOptions={<DropdownDestinationOption />}
      />
    </SearchDestinationContext.Provider>
  );
};

const DropdownDestinationOption = () => {
  const { selectedDestinations, items } = useContext(SearchDestinationContext);
  return (
    <>
      {items.map(item => {
        const isSelected = isSuggestedDestinationSelected(item.value, selectedDestinations);
        return (
          <Dropdown.Option
            {...testProps(`search-country-destination-${sluggify(item.value.longName)}`)}
            key={item.value.longName}
            value={item}
            className={dropdownOptionStyle({ isSelected })}>
            <>
              {!!item.value.countryFlagImage && (
                <img
                  src={item.value.countryFlagImage}
                  className={twMerge('rounded-md w-8 h-5 shadow-md')}
                  alt={item.value.countryName}
                />
              )}
              <DestinationName destination={item.value} />
              {isSelected && <Icon name="cancel" size="md" className="text-icon-brand-2" />}
            </>
          </Dropdown.Option>
        );
      })}
    </>
  );
};

const DestinationName = ({ destination }: { destination: TripDestination }) => {
  const { query } = useContext(SearchDestinationContext);
  const escapedSearchValue = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const matchingString = new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? '';

  return (
    <div className="flex-1">
      <Text variant="body-16/r">{destination.isCity && destination.countryName}</Text>
      <Text variant="body-16/r" className="inline">
        {destination.longName.split(matchingString).map((part, index) => {
          if (index / 2 !== 0) {
            return (
              <Text key={`${part}${index}`} variant={'body-16/r'} className="inline">
                <Text variant={'body-16/sb'} className="inline">
                  {new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? ''}
                </Text>
                {part}
              </Text>
            );
          }
          return (
            <Text key={`${part}${index}`} variant={'body-16/r'} className="inline">
              {part}
            </Text>
          );
        })}
      </Text>
    </div>
  );
};

const dropdownOptionStyle = tv({
  base: [
    'flex flex-row items-center justify-center gap-s16  rounded-lg cursor-pointer border border-transparent text-left',
    'hover:bg-surface-action-selected',
    'pl-s8 py-s8 pr-s16 mb-s8 last:mb-0',
  ],
  variants: {
    isSelected: {
      true: 'border-border-action-active bg-surface-action-selected',
    },
  },
});
