import type { DestinationCategory, TripDestination } from '../../../../types/src';

const MAX_DESTINATION_SEARCH_RESULTS = 5;

export const getPrimaryDestination = (destinations?: TripDestination[]) => {
  if (destinations && destinations.length === 0) {
    return '';
  }
  return destinations?.[0]?.longName ?? '';
};

export const getAllDestinations = (destinations?: TripDestination[]) => {
  if (destinations?.length === 0) {
    return '';
  }
  if (destinations?.length === 1) {
    return destinations[0]?.longName;
  }

  const firstSlice = destinations
    ?.slice(0, destinations.length - 1)
    .map(d => d.longName)
    .join(', ');

  return `${firstSlice} & ${destinations?.[destinations.length - 1]?.longName}`;
};

export const getDestinationsByCategory = (destinations: TripDestination[]) => {
  const _filterDestinationsByCategory = (
    destinations: TripDestination[],
    category: DestinationCategory,
  ) => {
    return destinations.filter(destination => destination.category === category);
  };
  const allRegionDestinations = _filterDestinationsByCategory(
    destinations,
    'allRegionDestinations',
  );
  const trendingDestinations = destinations.filter(destination => destination.isTrending);
  const allCruiseDestinations = _filterDestinationsByCategory(
    destinations,
    'allCruiseDestinations',
  );
  return {
    allRegionDestinations,
    trendingDestinations,
    allCruiseDestinations,
  };
};

export const getSearchSuggestions = (searchValue: string, destinations: TripDestination[]) => {
  const query = searchValue?.toLowerCase()?.trim();
  const resultsStartsWith = destinations.filter(
    d => d.longName.toLowerCase().includes(query) && d.longName.toLowerCase().startsWith(query),
  );

  const resultsContains = destinations.filter(
    destination =>
      !resultsStartsWith.find(d => d.longName === destination.longName) &&
      destination.longName.toLowerCase().includes(query),
  );

  const combinedResults = [...resultsStartsWith, ...resultsContains];

  const uniqueSearchResults: TripDestination[] = [];

  for (
    let i = 0;
    i < combinedResults.length && uniqueSearchResults.length < MAX_DESTINATION_SEARCH_RESULTS;
    i++
  ) {
    const combinedResult = combinedResults[i];
    if (
      combinedResult &&
      !uniqueSearchResults.some(
        d =>
          d.countryCode === combinedResult?.countryCode &&
          d?.countryId === combinedResult?.countryId,
      )
    ) {
      uniqueSearchResults.push(combinedResult);
    }
  }

  return getSortedDestinations(uniqueSearchResults);
};

export const isSuggestedDestinationSelected = (
  suggestedDestination: TripDestination,
  selectedDestinations: TripDestination[],
) =>
  selectedDestinations.some(destination => {
    return destination.countryCode === suggestedDestination.countryCode;
  });

/**
 * get sorted destinations by isTrending, isCity, countryName
 * @param destinations
 */
export const getSortedDestinations = (destinations: TripDestination[]): TripDestination[] => {
  return destinations.sort((a: TripDestination, b: TripDestination) =>
    (a.longName ?? '').localeCompare(b.longName ?? ''),
  );
};

export const getEmcRegionsFromDestinations = (destinations?: TripDestination[]) => {
  const regionCodesList = destinations?.map(d => d.emcRiskCode);
  return [...new Set(regionCodesList)].join(',');
};
