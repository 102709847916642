import { twMerge } from 'tailwind-merge';
import { Popover as PopoverComponent, Transition } from '@headlessui/react';
import { Text, TextProps } from '../text';
import { autoPlacement, offset, useFloating } from '@floating-ui/react-dom';
import { Button, ButtonProps } from '@elements/button';
import { HTMLAttributes } from 'react';

export type PopoverProps = {
  contentProps?: TextProps;
  titleProps?: TextProps;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  buttonProps: ButtonProps;
} & HTMLAttributes<HTMLDivElement>;

export const Popover = ({ titleProps, contentProps, className, buttonProps }: PopoverProps) => {
  const { refs, floatingStyles } = useFloating({
    middleware: [
      offset(10),
      autoPlacement({
        allowedPlacements: ['top-end', 'top-start', 'bottom-start', 'bottom-end'],
        autoAlignment: false,
      }),
    ],
  });
  return (
    <PopoverComponent className={twMerge('relative', className)}>
      <PopoverComponent.Button
        as={Button}
        ref={refs.setReference}
        size="sm"
        {...buttonProps}
        className={twMerge(
          'focus-visible:ring-0 focus-visible:outline-none',
          buttonProps.className,
        )}
      />
      <Transition
        className={twMerge('absolute z-10', contentProps?.className)}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <PopoverComponent.Panel
          ref={refs.setFloating}
          className="w-60 md:w-56 bg-nusa-50 rounded-[0.8rem] p-s12 shadow border-nusa-100 border-2"
          style={floatingStyles}>
          {({ close }) => (
            <div>
              <Button
                variant="tertiary"
                icon="cancel_filled"
                size="sm"
                onClick={() => close()}
                className="absolute top-s8 right-s8 md:hidden"
              />

              {titleProps ? (
                <Text
                  variant="subHeading-14/sb"
                  {...titleProps}
                  className={twMerge(
                    'flex justify-between items-center mb-4',
                    titleProps.className,
                  )}
                />
              ) : null}
              {contentProps ? <Text variant="subHeading-14/r" {...contentProps} /> : null}
            </div>
          )}
        </PopoverComponent.Panel>
      </Transition>
    </PopoverComponent>
  );
};
