import { openModalSelector, useModalStore, useTripStore } from '@store';

export const useCovidConsentModal = () => {
  const openModal = useModalStore(openModalSelector);
  const tripSortKey = useTripStore(state => state.trip?.sortKey);

  const openCovidConsentModal = () => {
    if (!tripSortKey) {
      return;
    }
    openModal('Modal', {
      body: {
        type: 'CovidConsent',
        isModal: true,
      },
      size: 'lg',
      sendExitEvent: true,
      shouldCloseOnOverlayClick: false,
      showCloseButton: false,
      bodyContainerClassName: `!text-left`,
    });
  };

  return { openCovidConsentModal };
};
