import { Boost } from '@packages/types';
import { useFeatureFlagsStore } from '@store/featureFlags';
import { useGlobalStore } from '@store/global';
import { isBoostExtraCancellation } from 'freely-shared-utils';
import { Text } from '@elements/text';
import { Popover } from '@components/popover';

export const CanstarExtraCancellationPopover = ({ boost }: { boost: Boost }) => {
  const isExtraCancellationAddedByDefaultForCanstar = useGlobalStore(
    state => state.isExtraCancellationAddedByDefaultForCanstar,
  );
  const isEnabled = useFeatureFlagsStore(
    state =>
      state.featureFlags?.['extra-cancellation-added-by-default-for-canstar-tooltip']?.isEnabled,
  );
  const isExtraCancellationBoost = isBoostExtraCancellation(boost);
  const canstarExtraCancellationPayload = useFeatureFlagsStore(
    state =>
      state.featureFlags?.['extra-cancellation-added-by-default-for-canstar-tooltip']?.payload,
  );
  if (isExtraCancellationAddedByDefaultForCanstar && isExtraCancellationBoost && isEnabled)
    return (
      <div className="flex flex-row">
        <Text variant="subHeading-14/r">
          {canstarExtraCancellationPayload?.extraCancellationPopover.title}
        </Text>
        <Popover
          titleProps={{
            children: canstarExtraCancellationPayload?.extraCancellationPopover.heading,
            withMarkDown: true,
          }}
          contentProps={{
            children: canstarExtraCancellationPayload?.extraCancellationPopover.content,
            withMarkDown: true,
          }}
          buttonProps={{
            variant: 'tertiary',
            icon: 'info',
            className: '-mt-s2 ml-s2',
          }}
        />
      </div>
    );

  return null;
};
