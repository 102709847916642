import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { colors } from '@packages/design';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import {
  isBoostEligible,
  isBoostExtraCancellation,
  isBoostSpecifiedItems,
  shouldShowNotEligibleBoostModal,
} from 'freely-shared-utils';

import { BOOST_CATEGORY, Boost } from '@packages/types';

import { Assets } from '@assets';
import { BoostPrice } from '@components';
import { Button } from '@elements/button';
import { Icon } from '@elements/icon';
import { useBoostSelection, usePreTripCancellationModal } from '@hooks';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { useBoostDetailsStore, useTripStore } from '@store';
import { sluggify, testProps } from '@utils';

import { numberOfItemsOrDays } from '../boostPrice/unit.component';
import { Text } from '../text';
import { CanstarExtraCancellationPopover } from './canstarExtraCancellationPopover.component';

export interface Props {
  boost: Boost;
}

const boostContainer = tv({
  base: 'relative m-auto mb-3 transition-all duration-300 flex flex-col rounded-lg min-h-[130px] sm:min-h-[80px]',
  variants: {
    variant: {
      default: 'bg-cabo-50' as const,
      isAdded: 'bg-nusa-50 ring-nusa-200 ring-2' as const,
      notEligible: 'bg-mono-100 border-cabo-50 border-2' as const,
    },
  },
});

const boostText = tv({
  base: 'inline-block',
  variants: {
    variant: {
      default: undefined,
      isAdded: undefined,
      notEligible: 'text-fuji-300' as const,
    },
  },
});
const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;

export const BoostListItem: React.FC<Props> = ({ boost }) => {
  const region = useRegionStore(getRegionSelector);
  const isEligible = isBoostEligible(boost, region);
  const boostId = boost?.boostId;
  const isAdded = boost?.toUpdate?.isAdded ?? boost.isAdded;

  const [isBoostDetailsLoading, setIsBoostDetailsLoading] = useState(false);
  const [isBoostUpdating, setIsBoostUpdating] = useState(false);

  const onBoostDetailsPress = async () => {
    setIsBoostDetailsLoading(true);
    await getBoostDetails(boostId);
    router.navigate(ROUTE_PATHS().boostDetails(boostId));
    setIsBoostDetailsLoading(false);
  };

  const openPreTripCancellationModal = usePreTripCancellationModal();

  const { onPrimaryAction, onEdit } = useBoostSelection({ boostId });

  /**
   * Invoked when the details pressed or when primary action of isn't available.
   */
  const onDetailsPress = async () => {
    if (isBoostDetailsLoading) {
      return undefined;
    }
    // If the boost is pre-trip cancellation is not eligible, open the pre-trip cancellation modal
    if (shouldShowNotEligibleBoostModal(boost, region)) {
      return openPreTripCancellationModal(boostId);
    }

    return await onBoostDetailsPress();
  };

  /**
   * states for the boost card
   */
  const variant = (isAdded && 'isAdded') || (!isEligible && 'notEligible') || 'default';
  const buttonVariant = (isAdded && 'secondary') || (!isEligible && 'secondary') || 'feature';

  const handleBoostAction = async () => {
    setIsBoostUpdating(true);
    if (isAdded || !isEligible) {
      await onDetailsPress();
    } else {
      await onPrimaryAction();
    }
    setIsBoostUpdating(false);
  };

  return (
    <div className={boostContainer({ variant })}>
      <div className="flex flex-col justify-between flex-1 m-3 sm:items-center sm:flex-row">
        <div
          className={twMerge(
            'flex flex-row justify-between sm:w-auto sm:flex-row',
            isAdded && 'flex-row-reverse',
          )}>
          <div
            className={twMerge(
              'bg-fuji-800 transition-all size-0 duration-150 opacity-0 rounded-xl  sm:mr-4 sm:self-center hidden sm:block',
              isAdded && 'opacity-100 size-[18px] block',
            )}>
            <Assets.CheckCircle
              fill={colors.nusa[200]}
              className="size-[20px] ml-[-1px] mt-[-1px]"
            />
          </div>

          <div className="flex flex-col gap-1">
            <div>
              <Text variant="body-16/sb" className={boostText({ variant })}>
                {boost?.name}&nbsp;
              </Text>
              {numberOfItemsOrDays(boost) && (
                <Text variant="body-16/r" className={boostText({ variant })}>
                  {numberOfItemsOrDays(boost)}
                </Text>
              )}
            </div>
            <CanstarExtraCancellationPopover boost={boost} />
            <Text
              {...testProps('boost-card-view-details-button')}
              className={boostText({ class: 'mr-2', variant })}
              variant="subHeading-14/r"
              onClick={onDetailsPress}>
              <a>View details</a>
            </Text>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full sm:items-center sm:w-auto">
          <div className="flex flex-row flex-1 w-full h-full pr-4 sm:flex-col sm:w-auto sm:items-end sm:pr-2">
            <BoostPrice boost={boost} type="boostCard" />
            {isAdded && <EditItem boost={boost} onClick={onEdit} />}
            {!isEligible && (
              <Button size="sm" variant="tertiary" icon="info" onClick={handleBoostAction} />
            )}
          </div>
          {!isAdded && (
            <Button
              {...testProps(sluggify(`boostCard-${boost?.name}`))}
              onClick={handleBoostAction}
              variant={buttonVariant}
              size="sm"
              disabled={!isEligible}
              title={getButtonI8Text(boost, isEligible)}
              isLoading={isBoostUpdating}
              className="w-[8rem]"
            />
          )}
          {isAdded && <RemoveButton boost={boost} />}
        </div>
      </div>
    </div>
  );
};

interface EditItemProps {
  boost: Boost;
  onClick: () => void;
}

const EditItem = ({ onClick, boost }: EditItemProps) => {
  const isMyStuffBoost = boost.category === BOOST_CATEGORY.MY_STUFF;
  const isMyActivitiesBoost = boost.category === BOOST_CATEGORY.MY_ACTIVITIES;

  const renderIcon =
    (isMyStuffBoost && (
      <Icon
        name="edit"
        className="text-[14px] text-link-default mr-1 cursor-pointer "
        onClick={onClick}
      />
    )) ||
    (isMyActivitiesBoost && (
      <Icon
        name="calendar_month"
        className="text-[14px] text-link-default mr-1 cursor-pointer "
        onClick={onClick}
      />
    )) ||
    null;

  const editName =
    (isMyStuffBoost &&
      isBoostExtraCancellation(boost) &&
      i18n.t('myQuote.boostListItem.editButton.unit.limit')) ||
    (isMyStuffBoost &&
      isBoostSpecifiedItems(boost) &&
      i18n.t('myQuote.boostListItem.editButton.unit.items')) ||
    (isMyActivitiesBoost && i18n.t('myQuote.boostListItem.editButton.unit.days')) ||
    null;

  if (!editName) {
    return null;
  }

  return (
    <span className="flex items-center">
      {renderIcon}
      <a className="flex flex-row">
        <Text className="flex underline cursor-pointer" variant="subHeading-14/r" onClick={onClick}>
          {i18n.t('myQuote.boostListItem.editButton.edit')}
          {editName && <span className="hidden md:inline">&nbsp;{editName}</span>}
        </Text>
      </a>
    </span>
  );
};

interface RemoveButtonProps {
  boost: Boost;
}

const RemoveButton = ({ boost }: RemoveButtonProps) => {
  const [isBoostUpdating, setIsBoostUpdating] = useState(false);
  return (
    <Button
      onClick={async () => {
        setIsBoostUpdating(true);
        await useTripStore.getState().removeBoost({ boostId: boost.boostId });
        setIsBoostUpdating(false);
      }}
      variant="secondary"
      size="sm"
      title={i18n.t('myQuote.boostListItem.primaryButton.remove')}
      isLoading={isBoostUpdating}
      className="w-[122px]"
    />
  );
};

const getButtonI8Text = (boost: Boost, isEligible?: boolean) => {
  if (!isEligible) {
    return i18n.t('myQuote.boostListItem.primaryButton.notEligible');
  }

  const isMyStuffBoost = boost.category === BOOST_CATEGORY.MY_STUFF;
  if (isMyStuffBoost && isBoostExtraCancellation(boost)) {
    return i18n.t('myQuote.boostListItem.primaryButton.selectLimit');
  }

  if (isMyStuffBoost && isBoostSpecifiedItems(boost)) {
    return i18n.t('myQuote.boostListItem.primaryButton.addItem');
  }

  if (isMyStuffBoost) {
    return i18n.t('myQuote.boostListItem.primaryButton.addToTrip');
  }

  const isMyActivitiesBoost = boost.category === BOOST_CATEGORY.MY_ACTIVITIES;
  if (isMyActivitiesBoost) {
    return i18n.t('myQuote.boostListItem.primaryButton.selectDays');
  }
};
