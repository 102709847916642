import React from 'react';

import { BoostCoverBulletPoints } from '@packages/types';

import { Icon } from '@elements/icon';
import { sluggify, testProps } from '@utils';

import { Text } from '../text';

interface Props {
  isCovered?: boolean;
  item: BoostCoverBulletPoints;
}

export const BulletPointsItem: React.FC<Props> = ({ item, isCovered }) => {
  return (
    <div>
      <ul>
        {item.value.map((value, index) => (
          <li key={index} className="flex py-1.5">
            <Icon
              name={isCovered ? 'check_circle_filled' : 'cancel_filled'}
              className={isCovered ? 'text-green-500' : 'text-error'}
            />

            <Text
              withMarkDown
              className="ml-3 [&>ul]:list-disc [&>ul]:ml-6"
              {...testProps(sluggify(value))}>
              {value}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};
