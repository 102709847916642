import { CARD_NUMBERS } from '@packages/constants';

import { PAYMENT_TYPE } from '../../../../types/src';

export function validateCardNumberByType(cardNumber: string, cardType: string) {
  if (!cardNumber || !cardType) {
    // Card type not found, so return false (not valid)
    return false;
  }

  const cardInfo = CARD_NUMBERS[cardType as PAYMENT_TYPE];

  if (!cardInfo) {
    // Card type not found, so return false (not valid)
    return false;
  }

  const { lengths } = cardInfo;

  if (!lengths) {
    // No lengths defined for this card type, return true (valid)
    return true;
  }

  // Check if the actual card number length matches any of the expected lengths
  const isValidLength = lengths.includes(cardNumber.length);

  return isValidLength;
}

// Function to get the card brand based on the card number
export function getCardBrand(cardNumber: string) {
  for (const brand in CARD_NUMBERS) {
    const brandInfo = CARD_NUMBERS[brand as PAYMENT_TYPE];
    if (!brandInfo) {
      return;
    }

    const { ranges, lengths } = brandInfo;
    if (!ranges || !lengths || ranges.length === 0) {
      return;
    }

    // Check if any of the card number ranges match the starting digits
    const matchesRange = ranges.some((range: string | string[]) => {
      const firstDigits = cardNumber.substring(0, range.length);
      return firstDigits === range;
    });

    if (matchesRange) {
      return brand;
    }
  }

  return undefined;
}
