import { CreateStore, createStore } from 'freely-shared-stores';

import { GuestDetails } from '@packages/types';

import * as guestApi from './guest.api';

export interface GuestState {
  guest: GuestDetails | undefined;
  pending: boolean;
  error: Error | undefined;
}

export interface GuestActions {
  setPending: (pending: boolean) => void;
  setError: (error: Error) => void;
  updateGuest: () => Promise<GuestDetails | undefined>;
  setGuest: (guest?: GuestDetails) => void;
  resetGuestState: () => void;
}

export type GuestStore = GuestActions & GuestState;

const guestInitialState: GuestState = {
  pending: false,
  guest: undefined,
  error: undefined,
};

export const guestStore: CreateStore<GuestStore> = (set, get) => ({
  ...guestInitialState,
  resetGuestState: () => set(guestInitialState, false),
  setPending: pending => {
    set(state => {
      state.pending = pending;
    });
  },
  setError: error => {
    set(state => {
      state.error = error;
    });
  },
  setGuest: guest => {
    const { guest: existingGuestData } = get();

    set(state => {
      state.guest = {
        ...existingGuestData,
        ...guest,
      };
    });
  },
  updateGuest: async () => await guestApi.updateGuest(get),
});

export const useGuestStore = createStore(guestStore, {
  name: 'useGuestStore',
});
