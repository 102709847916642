import isArray from 'lodash/isArray';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from '@packages/design';

import { ListItemVariants } from '@packages/types';

import { Assets } from '@assets';
import { Badge } from '@elements/badge';
import { i18n } from '@i18n';

import { Loader } from '../loader';
import { Text, TextProps } from '../text';

export interface ListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: ListItemVariants;
  titleTextProps: TextProps;
  subtitle?: string | string[];
  rightText?: TextProps;
  isTimezoneDisplayed?: boolean;
  onClick?: () => Promise<void> | void;
  className?: string;
  leftIcon?: ReactNode;
  backgroundColor?: string;
  disableHover?: boolean;
  rightIconProps?: React.SVGProps<SVGElement>;
  disabled?: boolean;
}

export const ListItem = ({
  variant = 'chevron',
  subtitle,
  rightText,
  isTimezoneDisplayed = false,
  onClick,
  className,
  leftIcon,
  backgroundColor,
  disabled = false,
  disableHover = disabled,
  rightIconProps,
  titleTextProps,
  ...rest
}: ListItemProps) => {
  const hover = !disableHover && 'hover:cursor-pointer hover:opacity-50 ';
  const leftTextContainer =
    variant !== 'edit' ? twMerge('rounded-[0.625rem]', hover) : 'rounded-l-[0.625rem] ';

  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    await onClick?.();
    setIsLoading(false);
  };

  /**
   * get icons based on variant
   * @param variant
   */
  const getIconVariant = (variant: ListItemVariants) => {
    switch (variant) {
      case 'chevron':
        return (
          <Assets.ArrowRight
            {...rightIconProps}
            className={`m-2 mt-1 h-4 ${rightIconProps?.className}`}
            fill={colors.fuji[50]}
            color={colors.fuji[50]}
          />
        );
      case 'noIcon':
        return <div className={`m-2 mt-1 h-4 ${rightIconProps?.className}`}> </div>;
      case 'remove':
        return (
          <>
            {isLoading ? (
              <Loader size="sm" />
            ) : (
              <Assets.CloseCircleLight
                {...rightIconProps}
                className={`m-2 mt-0.5 h-5 ${rightIconProps?.className}`}
                fill="gray"
              />
            )}
          </>
        );
      default:
        return (
          <Assets.ChevronDown
            className="m-2 mt-1 h-4"
            fill={colors.fuji[50]}
            color={colors.fuji[50]}
          />
        );
    }
  };

  const onItemClick = !disabled && variant !== 'edit' ? handleClick : undefined;

  return (
    <div className={twMerge('relative flex flex-row', className)} {...rest} onClick={onItemClick}>
      {variant !== 'remove' && isLoading && (
        <div
          className={twMerge(
            'bg-cabo-50/40 absolute inset-0 flex   w-full items-center justify-center text-center',
            backgroundColor,
          )}>
          <Loader size="md" />
        </div>
      )}
      <div
        className={twMerge(
          'bg-cabo-50 flex flex-auto flex-row ',
          leftTextContainer,
          backgroundColor,
        )}>
        <div className="flex flex-auto flex-col justify-center p-5">
          <div className="flex flex-row items-center mb-2">
            {leftIcon && <div className="mr-2">{leftIcon}</div>}
            <Text variant="subTitle-20/sb" className="inline-block" {...titleTextProps} />
            {isTimezoneDisplayed && (
              <Badge
                variant="disabled"
                title={i18n.t('checkout.sideBar.travelDates.timeZone')}
                className="ml-2"
              />
            )}
          </div>
          {isArray(subtitle) &&
            subtitle.map((item, index) => (
              <Text key={index} variant="body-16/m" className="text-fuji-800">
                {item}
              </Text>
            ))}
          {subtitle && !isArray(subtitle) && (
            <Text variant="body-16/m" className="text-fuji-800">
              {subtitle}
            </Text>
          )}
        </div>
        {variant !== 'edit' && (
          <div className="flex flex-col justify-center align-middle">
            <div className="flex flex-row justify-center align-middle">
              {rightText && <Text variant="subTitle-20/m" className="mr-4" {...rightText} />}
              {rightText && getIconVariant(variant)}
            </div>
          </div>
        )}
      </div>
      {variant === 'edit' && (
        <>
          <div className={'flex h-auto border border-mono-100'} />
          <div
            className={twMerge(
              'flex flex-col justify-center rounded-r-[0.625rem] align-middle bg-cabo-50',
              hover,
              backgroundColor,
            )}
            onClick={handleClick}>
            <div className="flex flex-row justify-center p-5 align-middle">
              {rightText ? (
                <Text variant="subHeading-14/r" className="text-body" {...rightText} />
              ) : (
                <Text variant="subHeading-14/r" className="text-fuji-800">
                  Edit
                </Text>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
