import { CreateStore, createStore } from 'freely-shared-stores';

import { BoostDetails, EmcDeclaring } from '@packages/types';

import { fetchBoostDetailsById } from './boost.api';

export interface BoostDetailsState {
  boostDetails: BoostDetails | undefined;
  mandatoryBoostDetails: BoostDetails | undefined;
  error: Error | undefined;
  pending: boolean;
  emcDeclaring: EmcDeclaring | null;
}

export interface BoostDetailsActions {
  getBoostDetails: (boostId: string) => Promise<BoostDetails | undefined>;
  setBoostDetails: (boostDetails?: BoostDetails) => void;
  setError: (error: Error) => void;
  setPending: (pending: boolean) => void;
  setEmcDeclaring: (data: EmcDeclaring) => void;
}

export type BoostDetailsStore = BoostDetailsState & BoostDetailsActions;

export const boostDetailsInitialState: BoostDetailsState = {
  pending: false,
  error: undefined,
  boostDetails: undefined,
  mandatoryBoostDetails: undefined,
  emcDeclaring: null,
};

export const boostDetailsStore: CreateStore<BoostDetailsStore> = (set, get) => ({
  ...boostDetailsInitialState,
  getBoostDetails: async boostId => await fetchBoostDetailsById(get, boostId),
  setBoostDetails: boostDetails => {
    set(state => {
      if (boostDetails?.isMandatory) {
        state.mandatoryBoostDetails = boostDetails;
      }
      state.boostDetails = boostDetails;
    });
  },
  setEmcDeclaring: data => {
    set(state => {
      state.emcDeclaring = data;
    });
  },
  setPending: pending => {
    set(state => {
      state.pending = pending;
    });
  },
  setError: error => {
    set(state => {
      state.error = error;
    });
  },
});

export const useBoostDetailsStore = createStore(boostDetailsStore, {
  name: 'useBoostDetailsStore',
});
