import { DateTime } from 'luxon';

import { getAge, getFullDateStr, isInPast, isInPastOrToday } from 'freely-shared-utils';

import { useRegionStore } from './region.store';

export const getRegionState = () => {
  return useRegionStore.getState().region;
};

export const getRegionCountry = () => {
  return useRegionStore.getState().region?.country;
};

export const regionDateUtils = () => {
  const currentCountry = useRegionStore.getState()?.region?.country;
  return {
    getFullDateStr: (
      isoDate: string | DateTime,
      options?: { ordinal: boolean; fullMonth: boolean },
    ) => getFullDateStr(isoDate, currentCountry, options),
    isInPast: (isoDate: string | DateTime) => isInPast(isoDate, currentCountry),
    isInPastOrToday: (isoDate: string | DateTime) => isInPastOrToday(isoDate, currentCountry),
    getAge: (dob: string | DateTime) => getAge(dob, currentCountry),
  };
};
