import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';

import { Text } from '@components';
import { i18n } from '@i18n';

type EmcNeedHelpProps = {
  className?: string;
};

export const EmcNeedHelp = ({ className }: EmcNeedHelpProps) => {
  const customerServiceNumber = useConfigStore(
    state => state.regionSpecificConfig?.CUSTOMER_SERVICE_NUMBER,
  );
  const customerServiceNumberLink = customerServiceNumber?.replace(/\s+/g, '');

  return (
    <Text
      withMarkDown
      variant="body-16/sb"
      className={twMerge('[&>a]:text-cabo-700 [&>a]:hover:text-opacity-80', className)}>
      {i18n.t('emcShared.global.contact.web', {
        customerServiceNumber,
        customerServiceNumberLink,
      })}
    </Text>
  );
};
