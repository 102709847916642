import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { isUSRegionSelector, useRegionStore } from 'freely-shared-stores';

import { Text } from '@components/text';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { testProps } from '@utils';

import { BenefitsAndExcess } from './benefitsAndExcess';
import { BenefitsItem } from './benefitsItem/benefitsItem.component';

export const AccordionContainer = () => {
  const [hasFullBenefitsDisplayed, setHasFullBenefitsDisplayed] = useState(false);
  const isUS = useRegionStore(isUSRegionSelector);
  const shouldShowDisclaimer =
    useBoostDetailsStore(state => state?.mandatoryBoostDetails?.code === 'OMD') && !isUS;
  const handleClick = () => {
    setHasFullBenefitsDisplayed(prev => !prev);
  };

  return (
    <>
      <div
        className={twMerge(
          'relative overflow-hidden max-h-[44rem] md:max-h-[35rem] ease-in-out lg:max-h-[40rem] transition-all duration-400 after:content-[" "] after:h-10 after:border-fuji-800 after:w-full after:bottom-0 after:absolute after:bg-gradient-to-t from-mono-100',
          hasFullBenefitsDisplayed && '!max-h-[125rem] after:!bg-gradient-none',
          isUS && 'max-h-[36.5rem] md:max-h-[35rem] ease-in lg:max-h-[36.5rem]',
        )}>
        <BenefitsItem />
      </div>
      <BenefitsAndExcess
        hasFullBenefitsDisplayed={hasFullBenefitsDisplayed}
        onClick={handleClick}
      />
      {shouldShowDisclaimer && (
        <Text
          className="text-center my-4"
          variant="footnote-12/r"
          {...testProps('medical-dental-disclaimer')}>
          {i18n.t('myQuote.medicalAndDentalDisclaimer')}
        </Text>
      )}
    </>
  );
};
