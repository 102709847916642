import { FC, useState } from 'react';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import {
  consolidateDateRanges,
  getDurationOfDatesInDays,
  getDurationOfRangesInDays,
} from 'freely-shared-utils';

import { Boost, SelectedDuration } from '@packages/types';

import { i18n } from '@i18n';
import {
  closeModalSelector,
  getSelectedBoostById,
  tripEndDateSelector,
  tripStartDateSelector,
  useModalStore,
  useTripStore,
} from '@store';

import { BoostModalContainer } from '../boostModalContainer';
import { Calendar } from '../calendar';

export type BoostCalendarModalProps = {
  boostId: string;
  onSuccess?: () => void;
};

export const BoostCalendarModal: FC<BoostCalendarModalProps> = ({ boostId, onSuccess }) => {
  const addBoost = useTripStore(state => state.addBoost);
  const startDate = useTripStore(tripStartDateSelector);
  const endDate = useTripStore(tripEndDateSelector);
  const region = useRegionStore(getRegionSelector);
  const selectedBoost = useTripStore(getSelectedBoostById(boostId));
  const closeModal = useModalStore(closeModalSelector);

  const isMultiDateRange = region?.country === 'AU';

  const payPerDayRate =
    selectedBoost?.toUpdate?.boostProperty?.payPerDayRate ??
    selectedBoost?.boostProperty?.payPerDayRate ??
    0;

  const [selectedDuration, setSelectedDuration] = useState<SelectedDuration>(
    selectedBoost?.toUpdate
      ? selectedBoost?.toUpdate?.startEndDates?.[0]
      : selectedBoost?.startEndDates?.[0],
  );

  const selectRangeDates = selectedBoost?.toUpdate?.startEndDates ?? selectedBoost?.startEndDates;

  const [selectedRanges, setSelectedRanges] = useState<{ startDate: string; endDate: string }[]>(
    selectRangeDates ?? [],
  );

  const [boostWithModifiedPrice, setBoostWithModifiedPrice] = useState<Boost | undefined>(
    selectedBoost,
  );

  const canSubmit = isMultiDateRange
    ? selectedRanges?.length > 0
    : selectedDuration?.startDate && selectedDuration?.endDate;

  const handleSelectDateDuration = (startDate: string, endDate: string) => {
    const duration = getDurationOfDatesInDays(startDate, endDate, region?.country);
    const price = `${payPerDayRate * duration}`;
    setSelectedDuration({ startDate, endDate });

    setBoostWithModifiedPrice(prevState => {
      if (prevState) {
        return { ...prevState, duration, price };
      }
    });
  };

  const handleMultiRange = (newRanges: { startDate: string; endDate: string }[]) => {
    const consolidateRanges = consolidateDateRanges(newRanges, region);
    setSelectedRanges(consolidateRanges);

    const duration = getDurationOfRangesInDays(consolidateRanges, region?.country);
    const price = `${payPerDayRate * duration}`;
    setBoostWithModifiedPrice(prevState => {
      if (prevState) {
        return { ...prevState, duration, price };
      }
    });
  };

  const onSubmit = async () => {
    if (!canSubmit || !selectedBoost?.boostId) {
      return;
    }
    let boostResponse;
    if (isMultiDateRange) {
      boostResponse = await addBoost({
        boostId: selectedBoost.boostId,
        updatePayload: {
          startEndDates: selectedRanges,
        },
      });
    } else {
      boostResponse = await addBoost({
        boostId: selectedBoost.boostId,
        updatePayload: {
          startEndDates: [
            {
              startDate: selectedDuration?.startDate ?? '',
              endDate: selectedDuration?.endDate ?? '',
            },
          ],
        },
      });
    }

    if (boostResponse) {
      onSuccess?.();
    }
    closeModal();
  };

  return (
    <BoostModalContainer
      isBoostModalFooterDisabled={!canSubmit}
      boost={boostWithModifiedPrice}
      onSubmit={onSubmit}
      titleProps={{
        children: i18n.t('boostModals.payPerDay.title'),
      }}>
      <div className="py-2 pt-2 pb-4">
        <Calendar
          currentDate={startDate}
          minDate={startDate}
          maxDate={endDate}
          onSelectDuration={handleSelectDateDuration}
          startDate={selectedDuration?.startDate}
          endDate={selectedDuration?.endDate}
          country={region?.country ?? 'AU'}
          highlightedDateRange={{ startDate, endDate }}
          showDefaultDuration
          multiDateRange={isMultiDateRange}
          selectedRange={selectedRanges}
          onSelectedRangeChange={handleMultiRange}
        />
      </div>
    </BoostModalContainer>
  );
};
