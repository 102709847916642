import { Fragment, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postEmcEmailAssessmentLetter } from 'freely-shared-api';
import { getRegionState, useConfigStore } from 'freely-shared-stores';
import { emailAssessmentSchema, getEmcBoost } from 'freely-shared-utils';

import { EMCEmailAssessment, EmcDataType } from '@packages/types';

import { Assets } from '@assets';
import { ModalBodyContainer, Text } from '@components';
import { Button } from '@elements/button';
import { Input } from '@elements/input';
import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { getTripSelector, useEmcStore, useTripStore } from '@store';
import { logToSentry, testProps } from '@utils';

export const EmcEmailAssessmentSection = () => {
  const { emcCurrentTraveller } = useEmcTraveller();
  const trip = useTripStore(getTripSelector);
  const region = getRegionState();
  const emcBoost = getEmcBoost(trip);
  const { assessmentNumber } = emcCurrentTraveller as EmcDataType;
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { control, handleSubmit } = useForm<EMCEmailAssessment>({
    resolver: zodResolver(emailAssessmentSchema),
  });
  const privacyPolicyLink = useConfigStore(state => state?.config?.AU.PRIVACY_STATEMENT);
  const termsLink = useConfigStore(state => state?.config?.AU.APP_TERMS);

  const handleFormSubmit = useCallback(
    async (data: EMCEmailAssessment) => {
      if (!region || !assessmentNumber) return;
      setIsLoading(true);
      try {
        await postEmcEmailAssessmentLetter({
          name: data.name,
          email: data.email,
          assessmentNumber,
          boostId: emcBoost?.boostId ?? '',
          region: region,
        });
        setOpenEmcSection(undefined);
      } catch (error) {
        setErrorMessage((error as Error)?.message);
        logToSentry(new Error('Failed to email medical assessment letter.' + error));
      } finally {
        setIsLoading(false);
      }
    },
    [assessmentNumber, emcBoost?.boostId, region, setOpenEmcSection],
  );

  return (
    <Transition.Child as={Fragment}>
      <div className="fixed inset-0 overflow-hidden z-30 ">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex bg-nusa-100">
            <div className="pointer-events-auto w-screen">
              <div className="flex h-full items-center justify-center max-w-4xl mx-auto overflow-y-auto overflow-x-hidden bg-nusa-100 ">
                <ModalBodyContainer
                  icon={<Assets.AlertOutline />}
                  header={
                    <Text variant="h3-28/sb" {...testProps('emc-send-copy-title')}>
                      {i18n.t('emcShared.global.modal.rejected.title')}
                    </Text>
                  }
                  description={
                    <>
                      <Text variant="body-16/r" {...testProps('emc-send-copy-description')}>
                        {i18n.t('emcShared.global.modal.rejected.description')}
                      </Text>
                      <form className="w-full">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="mb-4">
                              <Input
                                {...field}
                                placeholder="Complete Name"
                                errorMessage={error?.message}
                                {...testProps('emc-send-copy-input-name')}
                              />
                            </div>
                          )}
                        />
                        <Controller
                          name="email"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="mb-4">
                              <Input
                                {...field}
                                placeholder="Email Address"
                                errorMessage={error?.message}
                                {...testProps('emc-send-copy-input-email')}
                              />
                            </div>
                          )}
                        />
                        <Text
                          variant="subHeading-14/r"
                          className="mb-4 text-left [&>a]:text-fuji-800"
                          withMarkDown
                          {...testProps('emc-send-copy-consent')}>
                          {i18n.t('emcShared.global.modal.rejected.terms', {
                            PRIVACY_POLICY: privacyPolicyLink,
                            TERMS: termsLink,
                          })}
                        </Text>
                        {errorMessage && (
                          <Text
                            variant="subHeading-14/r"
                            className="text-red-500 mb-4 text-left"
                            {...testProps('emc-send-copy-error')}>
                            {errorMessage}
                          </Text>
                        )}

                        <div className="flex flex-col sm:flex-row justify-between space-x-0 sm:space-x-6">
                          <Button
                            className="w-full mb-5 sm:mb-0"
                            variant="secondary"
                            onClick={() => setOpenEmcSection(undefined)}
                            title={i18n.t('emcShared.global.modal.rejected.actions.primary')}
                            {...testProps('emc-send-copy-btn-secondary')}
                          />
                          <Button
                            isLoading={isLoading}
                            className="w-full"
                            variant="primary"
                            onClick={handleSubmit(handleFormSubmit)}
                            title={i18n.t('emcShared.global.modal.rejected.actions.secondary')}
                            {...testProps('emc-send-copy-btn-primary')}
                          />
                        </div>
                      </form>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition.Child>
  );
};
