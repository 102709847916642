import { Trip } from '@packages/types';
import { getBoostSelectedExtraCover, getSelectedExtraCoverPrice } from 'freely-shared-utils';
import { useGlobalStore } from './global.store';
import { useTripStore } from '@store/trip';
import { useFeatureFlagsStore } from '@store/featureFlags';

export const checkAndEnableCanstarExtraCancellationAddedByDefaultState = async (
  trip: Trip | undefined,
) => {
  // if boost is extra cancellation and partnername is canstar
  const isEnabled =
    useFeatureFlagsStore.getState().featureFlags?.[
      'extra-cancellation-added-by-default-for-canstar-tooltip'
    ]?.isEnabled;

  if (!isEnabled) return;
  const setIsExtraCancellationAddedByDefaultForCanstar =
    useGlobalStore.getState().setIsExtraCancellationAddedByDefaultForCanstar;

  const setAddedSelectedCover = useTripStore.getState().setAddedSelectedCover;
  const extraCancellationBoost = trip?.boosts.find(boost => boost.code === 'CANX');
  const canstarExtraCancellationPayload =
    useFeatureFlagsStore.getState().featureFlags?.[
      'extra-cancellation-added-by-default-for-canstar-tooltip'
    ]?.payload;
  if (
    extraCancellationBoost?.isAdded &&
    trip?.metaData?.quotePartnerName === 'AUCanstar' &&
    extraCancellationBoost?.boostProperty?.selectedExtraCover ===
      canstarExtraCancellationPayload?.canstarCanxAmount
  ) {
    const updatedSelectedCover = getBoostSelectedExtraCover(extraCancellationBoost) ?? undefined;
    const updatedSelectedCoverPrice = getSelectedExtraCoverPrice(extraCancellationBoost);
    setIsExtraCancellationAddedByDefaultForCanstar(true);
    setAddedSelectedCover({
      selectedCover: updatedSelectedCover,
      price: updatedSelectedCoverPrice as number,
    });
  }
};
