import { FC } from 'react';

import { regionDateUtils } from 'freely-shared-stores';

import { useTripStore } from '@store';

import { ListItem, ListItemProps } from '../../listItem';
import { useEditDepositDateModal } from './useEditDepositDateModal';

export type Props = Pick<ListItemProps, 'disabled' | 'variant'>;

export const BookingDateListItem: FC<Props> = ({ disabled, variant }) => {
  const depositDate = useTripStore(state => state.trip?.depositDate);

  const { openEditDepositDateModal } = useEditDepositDateModal();

  if (!depositDate) {
    return null;
  }

  return (
    <ListItem
      variant={variant ?? 'edit'}
      titleTextProps={{ children: 'Booking Date' }}
      subtitle={regionDateUtils().getFullDateStr(depositDate)}
      className="my-3"
      onClick={openEditDepositDateModal}
      disabled={disabled}
    />
  );
};
