import { Controller, useFormContext } from 'react-hook-form';

import { validateResidencyCheckBox } from 'freely-shared-utils';

import { TravellersForm } from '@packages/types';

import { Button } from '@elements/button';
import { useTravellersInfoModal } from '@hooks';
import { i18n } from '@i18n';

import { Checkbox } from '../checkbox';
import { Text } from '../text';

/**
 * Resident status component toggle which is used to accept the resident status of all travellers
 */
export const ResidentStatus = () => {
  const { setValue, getValues, control } = useFormContext<TravellersForm>();
  const { openMoreInfoModal } = useTravellersInfoModal();

  // computed state
  const formPrimaryTravellerIsResident = getValues('primaryTravellerIsResident');
  const formSecondaryTravellers = getValues('secondaryTravellers') ?? [];
  const isChecked =
    formPrimaryTravellerIsResident && formSecondaryTravellers.every(it => it.isResident);

  return (
    <div>
      <div className="flex py-4 items-center">
        <Text variant="subTitle-20/sb">{i18n.t('travellers.residentStatus.title')}</Text>
        <Button size="sm" variant="tertiary" icon="info" onClick={() => openMoreInfoModal()} />
      </div>
      <Controller
        name="primaryTravellerIsResident"
        control={control}
        rules={{
          validate: {
            validateResidency: v =>
              validateResidencyCheckBox({
                formPrimaryTravellerIsResident: v,
                formSecondaryTravellers: formSecondaryTravellers,
              }),
          },
        }}
        render={({ field: { onChange } }) => (
          <Checkbox
            className="mb-3"
            isChecked={isChecked}
            labelProps={{
              children: i18n.t('travellers.residentStatus.checkbox.label'),
              className: '!text-left',
            }}
            onClick={() => {
              onChange(!isChecked);
              formSecondaryTravellers.forEach((_, index) => {
                setValue(`secondaryTravellers.${index}.isResident`, !isChecked, {
                  shouldValidate: true,
                });
              });
            }}
          />
        )}
      />
    </div>
  );
};
