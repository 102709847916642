import { FC } from 'react';

import { useRegionStore } from 'freely-shared-stores';

import { CovidConsent as AU, CovidConsentProps as AUProps } from './au/covidConsent.component';
import { CovidConsent as US, CovidConsentProps as USProps } from './us/covidConsent.component';

export type CovidConsentProps = AUProps & USProps;
export const CovidConsent: FC<AUProps & USProps> = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent<AUProps & USProps>({ AU, US });
  return <Component {...props} />;
};
