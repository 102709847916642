import { getEmcBoost } from 'freely-shared-utils';

import { Boost } from '@packages/types';

import { useBoostDetailsStore, useTripStore } from '@store';
import { calculateEmcBoostPrice, hasTravellerErrors, validateEmcTravellers } from '@utils';

export const useEmcTraveller = () => {
  /* emc current selected traveller */
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);
  const emcBoost = useTripStore(state => getEmcBoost(state.trip));
  const emcCurrentTraveller = emcBoost?.boostProperty?.emcData?.find(
    emc => emc?.travellerId === emcDeclaring?.travellerId,
  );

  /* emc calculate total price of all emc boosts. This includes current selected traveller as well. */
  const emcBoostTotalPrice = calculateEmcBoostPrice(emcBoost?.boostProperty?.emcData ?? []);

  /* get emc traveller by id */
  const getEmcTravellerById = (travellerId?: string) => {
    if (!travellerId) return undefined;
    return emcBoost?.boostProperty?.emcData?.find(emc => emc?.travellerId === travellerId);
  };

  const hasEmcTravellerValidated = (emcBoost?: Boost) => {
    if (!emcBoost) return;
    const errors = validateEmcTravellers(emcBoost);
    return hasTravellerErrors(errors);
  };

  return {
    emcCurrentTraveller,
    emcBoost,
    emcBoostTotalPrice,
    emcDeclaring,
    getEmcTravellerById,
    hasEmcTravellerValidated,
  };
};
