import { FC } from 'react';

import { convertCentsToDollars, isBoostAdded } from 'freely-shared-utils';

import { ROUTE_PATHS, router } from '@routes/router';
import { useBoostDetailsStore, useTripStore } from '@store';
import { testProps } from '@utils';

import { ListItem } from '../../listItem';
import { Text, TextProps } from '../../text';

export interface TripSummaryProps extends React.HTMLAttributes<HTMLDivElement> {
  titleProps?: TextProps;
}
const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
export const TripSummary: FC<TripSummaryProps> = ({ titleProps, ...rest }) => {
  const allAddedBoosts = useTripStore(state =>
    state.trip?.boosts.filter(boost => isBoostAdded(boost)),
  );

  if (allAddedBoosts.length === 0) {
    return null;
  }

  const addedBoosts = allAddedBoosts.filter(boost => boost.code !== 'EMC');
  const emcBoost = allAddedBoosts.find(boost => boost.code === 'EMC');

  return (
    <div {...rest}>
      {titleProps && <Text className="mt-3" variant="h4-24/sb" {...titleProps} />}
      {addedBoosts.map(boost => (
        <ListItem
          {...testProps(`boost-item-${boost.name}`)}
          key={boost.boostId}
          variant="chevron"
          titleTextProps={{ children: boost.name }}
          className="mt-3"
          subtitle={`${boost.duration} ${boost.duration > 1 ? 'days' : 'day'}`}
          rightText={{ children: convertCentsToDollars(boost.price) }}
          onClick={async () => {
            await getBoostDetails(boost.boostId);
            await router.navigate(ROUTE_PATHS().boostDetails(boost.boostId));
          }}
        />
      ))}
      {emcBoost && (
        <ListItem
          {...testProps(`boost-item-${emcBoost.name}`)}
          key={emcBoost.boostId}
          variant="noIcon"
          titleTextProps={{ children: emcBoost.name }}
          className="mt-3"
          subtitle={`${emcBoost.duration} ${emcBoost.duration > 1 ? 'days' : 'day'}`}
          rightText={{ children: convertCentsToDollars(emcBoost.price), className: 'mr-10' }}
          disableHover
        />
      )}
    </div>
  );
};
